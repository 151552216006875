// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import {
  RESET_USER_DATA,
  SAVE_AUTHORIZATION_DATA,
  SAVE_NAME_ID,
  SAVE_ROUTE,
  SAVE_SESSION_INDEX,
  SAVE_USER_CLAIMS,
  SAVE_USER_INFO,
  SAVE_AUTH_SERVICE_API_TOKEN,
  STORE_USER_GROUP_TYPE_IDS
} from '../actions/user'

import {
  DATA_GET_USERS_SELF_SUCCESSFUL,
  DATA_GET_USERS_SELF_ACCOUNTS_SUCCESSFUL,
  DATA_GET_USERS_SELF_PRIVILEGES_SUCCESSFUL
} from '../utils/generated-api-requests/users'
import { ACCOUNT_ADMINISTRATOR } from '../constants/privileges'

const initialState = {
  // the initial claims values are needed when running the TakuPro web app without auth functionality
  claims: {
    image: 'person_outline',
    userId: undefined,
    organizationId: 'b828724a-5025-4d91-a11f-d57f4dea2c25',
    firstName: 'Essi',
    lastName: 'Esimerkki',
    emailAddress: 'essi.esimerkki.haahtela.fi',
    phone: '+358 123 456 7890',
    haahtelaApiUserToken: '',
  },
  authorization: {},
  route: '',
  accounts: [],
  isAccountAdministrator: false,
  userGroupTypeIds: [],
  userPrivileges: []
}

export default function userReducer(state: Object = initialState, action: Object = {}): Object {
  switch (action.type) {
    case SAVE_ROUTE: {
      const { route } = action
      return {
        ...state,
        route
      }
    }
    case SAVE_NAME_ID: {
      const { nameId } = action
      return {
        ...state,
        nameId
      }
    }
    case SAVE_SESSION_INDEX: {
      const { sessionIndex } = action
      return {
        ...state,
        sessionIndex
      }
    }
    case SAVE_USER_CLAIMS: {
      const { payload: claims } = action
      return {
        ...state,
        claims: {
          ...state.claims,
          ...claims,
        },
      }
    }
    case DATA_GET_USERS_SELF_SUCCESSFUL: {
      const { payload: { parsedResponse: claims } } = action
      return {
        ...state,
        claims: {
          ...state.claims,
          ...claims,
        },
      }
    }
    case RESET_USER_DATA: {
      return {
        ...state,
        accessToken: {},
        nameId: undefined,
        sessionIndex: undefined,
        route: undefined,
        accounts: []
      }
    }
    case SAVE_AUTH_SERVICE_API_TOKEN: {
      const { authServiceApiToken } = action
      return {
        ...state,
        authServiceApiToken
      }
    }
    case SAVE_AUTHORIZATION_DATA: {
      const { payload } = action
      return {
        ...state,
        authorization: payload || {},
      }
    }
    case SAVE_USER_INFO: {
      const { payload } = action
      const { user, userApps } = payload
      return {
        ...state,
        apiUser: { ...user, apps: userApps }
      }
    }
    case DATA_GET_USERS_SELF_ACCOUNTS_SUCCESSFUL: {
      const { payload: { parsedResponse } } = action
      return {
        ...state,
        accounts: parsedResponse
      }
    }
    case DATA_GET_USERS_SELF_PRIVILEGES_SUCCESSFUL: {
      const { payload: { parsedResponse } } = action
      return {
        ...state,
        isAccountAdministrator: !!parsedResponse.find(({ value }: TVDEnum): boolean => value === ACCOUNT_ADMINISTRATOR),
        userPrivileges: parsedResponse
      }
    }
    case STORE_USER_GROUP_TYPE_IDS: {
      const { payload: { userGroupTypeIds } } = action
      return {
        ...state,
        userGroupTypeIds,
      }
    }
    default:
      return state
  }
}
