// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { loadMFE, registeredMFEs } from '../../../utils/MFEUtils'
import { API_BASE_URL, ALLOW_WITH_CREDENTIALS } from '../../../constants/envConstants'
import { openGenericErrorModal } from '../../../utils/modalUtils'
import { dispatch } from '../../../store'
import { setCalculationActive, setCalculationComplete, setActiveWidgetAsTopmost } from '../../../actions/app'
import { postPolling } from '../../../actions/postPolling'
import { defaultLocation } from '../../../middlewares/widgetLocationMiddleware'

type Props = {|
  setIsWidgetOpen: Function,
  isWidgetOpen: boolean
|}

export const buildingElementsScheduleWidgetMFERootId = 'building-elements-schedule-widget'

const BuildingElementsScheduleWidgetMFEContainer = ({
  setIsWidgetOpen,
  isWidgetOpen
}: Props): null => {
  const {
    realEstateId,
    accessToken,
    haahtelaApiUserToken,
    userId,
    languageCode,
    selectedAccountId,
    activeEstimate: activeSpacesEstimate,
    postPollingTS,
    activeEdit,
    activeCalculation,
    estimateType,
    resultViewType,
    isEstimateLockedToCurrentUser,
    widgetOrder
  } = useSelector(({
    activeEstimate,
    app,
    user,
  }: TVDReduxStore): {|
    languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
    selectedAccountId: $PropertyType<TVDApplicationStore, 'selectedAccountId'>,
    accessToken: $PropertyType<TVDUserAuthorization, 'accessToken'>,
    haahtelaApiUserToken: $PropertyType<TVDUserClaims, 'haahtelaApiUserToken'>,
    userId: $PropertyType<TVDUserClaims, 'userId'>,
    activeEstimate: TVDActiveEstimateStore,
    realEstateId: $PropertyType<TVDApplicationStore, 'realEstateId'>,
    postPollingTS: $PropertyType<TVDApplicationStore, 'postPollingTS'>,
    activeEdit: $PropertyType<TVDApplicationStore, 'activeEdit'>,
    activeCalculation: $PropertyType<TVDApplicationStore, 'activeCalculation'>,
    estimateType: $PropertyType<TVDApplicationStore, 'spacesEstimateType'>,
    resultViewType: $PropertyType<TVDApplicationStore, 'spacesResultView'>,
    isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>,
    widgetOrder: $PropertyType<TVDApplicationStore, 'widgetOrder'>,
  |} => ({
    languageCode: app.languageCode,
    selectedAccountId: app.selectedAccountId,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    activeEstimate,
    realEstateId: app.realEstateId,
    postPollingTS: app.postPollingTS,
    activeEdit: app.activeEdit,
    activeCalculation: app.activeCalculation,
    estimateType: app.spacesEstimateType,
    resultViewType: app.spacesResultView,
    isEstimateLockedToCurrentUser: app.isEstimateLockedToCurrentUser,
    widgetOrder: app.widgetOrder
  }))

  const updatePostPollingTS = () => {
    dispatch(postPolling())
  }

  const dispatchSetCalculationActive = () => {
    dispatch(setCalculationActive())
  }

  const dispatchSetCalculationComplete = () => {
    dispatch(setCalculationComplete())
  }

  const registeredSpacesAPIModule = registeredMFEs['spaces-api-module']
  const rootElement = document.getElementById(buildingElementsScheduleWidgetMFERootId)

  useEffect(() => {
    if (
      languageCode &&
      accessToken &&
      haahtelaApiUserToken &&
      userId &&
      selectedAccountId &&
      activeSpacesEstimate &&
      realEstateId &&
      estimateType &&
      resultViewType &&
      setIsWidgetOpen
    ) {
      const render = (module: TVDMFEFunctionality): void => {
        const moduleRun = module.run || null
        if (moduleRun && !!rootElement) {
          const setWidgetAsTopmost = () => {
            if (widgetOrder[widgetOrder.length - 1] !== buildingElementsScheduleWidgetMFERootId) {
              dispatch(setActiveWidgetAsTopmost(buildingElementsScheduleWidgetMFERootId))
            }
          }
          moduleRun(
            'renderScheduleWidget',
            [
              buildingElementsScheduleWidgetMFERootId,
              {
                APIBaseURL: API_BASE_URL,
                languageCode,
                accessToken,
                haahtelaApiUserToken,
                userId,
                selectedAccountId,
                openGenericErrorModal,
                ALLOW_WITH_CREDENTIALS,
                realEstateId,
                activeEstimate: activeSpacesEstimate,
                postPollingTS,
                updatePostPollingTS,
                activeEdit,
                activeCalculation,
                estimateType,
                resultViewType,
                isEstimateLockedToCurrentUser,
                setCalculationActive: dispatchSetCalculationActive,
                setCalculationComplete: dispatchSetCalculationComplete,
                setIsWidgetOpen,
                isWidgetOpen,
                setWidgetAsTopmost,
                widgetOrder,
                defaultLocation
              }
            ]
          )
        }
      }
      if (registeredSpacesAPIModule) {
        render(registeredSpacesAPIModule)
      } else {
        loadMFE('spaces', 'spaces-api-module', (module: TVDMFEFunctionality): void => {
          render(module)
        })
      }
    }
  }, [
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    selectedAccountId,
    activeSpacesEstimate,
    realEstateId,
    postPollingTS,
    activeEdit,
    activeCalculation,
    registeredSpacesAPIModule,
    estimateType,
    resultViewType,
    isEstimateLockedToCurrentUser,
    setIsWidgetOpen,
    isWidgetOpen,
    widgetOrder
  ])
  return null
}

export default BuildingElementsScheduleWidgetMFEContainer

