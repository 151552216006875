// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import PropertyContainer from '../../../common/Tabs/Properties/PropertyContainer/PropertyContainer'
import TabsPlain from '../../../common/TabsPlain/TabsPlain'
import Equipments from '../../../common/Tabs/Equipments/Equipments'
import Surfaces from '../../../common/Tabs/Surfaces/Surfaces'

import { PROPERTIES_TAB, EQUIPMENTS_TAB, SURFACES_TAB } from '../../../../constants/moduleConstants'
import { SPACE, SPACEGROUP } from '../../../../constants/contentTypes'
import { viewMode } from '../../../../constants/viewModeConstants'
import { getIsFeatureEnabledInSet } from '../../../../utils/features'
import { FEATURE_SPACES_EQUIPMENT_TAB, FEATURE_SPACES_SURFACES_TAB } from '../../../../constants/features'
import SpacesEquipmentTabMFEContainer from '../../SpacesEquipmentTabMFEContainer/SpacesEquipmentTabMFEContainer'
import SpaceEquipmentProductAssemblyMFEContainer from '../../SpaceEquipmentProductAssemblyMFEContainer/SpaceEquipmentProductAssemblyMFEContainer'
import SpaceSurfacesTabMFEContainer from '../../SpaceSurfacesTabMFEContainer/SpaceSurfacesTabMFEContainer'
import { setComponentPreferencesSelectedTab } from '../../../../actions/componentPreferences'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    minHeight: 0, // fixes vertical scrolling when using firefox https://stackoverflow.com/a/28639686
  },
  contentWrapper: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0%'
  },
}

type DispatchProps = {|
  dispatchSetSelectedTab: (widgetType: string, selectedTab: string) => void
|}

type OwnProps = {|
  t: Function, // translate function
  classes: Object, // withStyles classes object
  spaceId: string, // id of the space
  activeTab: string, // name of edited tab
  disabled: boolean, // flag to disable widget content
  widgetType: string, // widget type constant e.g SPACE, SPACEGROUP..
  widgetSize: Object, // current size of the widget, used to trigger scrollFade re-render on widget resize
  isEstimateLockedToCurrentUser: boolean, // if estimate is locked to current user
  propertiesStoreId: string, // store key that represents where to access spaces properties
  equipmentsListStoreId: string, // store key that represents where to access spaces equipments list
  surfacesListStoreId: string, // store key that represents where to access spaces surfaces list
  widgetId: string, // key for the widget in Store
  spacesEstimateType: $PropertyType<TVDApplicationStore, 'spacesEstimateType'>, // what estimate type is selected
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>, // if estimate is frozen
  selectedTab: number,
|}

type Props = {|
  ...OwnProps,
  ...DispatchProps
|}

type State = {
  isSpaceEquipmentProductAssemblyWidgetOpen: boolean,
  selectedSpaceEquipmentAssemblyId: string | null,
  initialSelectedTab: number | null
}

export class Space extends Component<Props, State> {
  static defaultProps = {
    isEstimateLockedToCurrentUser: false,
  }

  state = {
    isSpaceEquipmentProductAssemblyWidgetOpen: false,
    selectedSpaceEquipmentAssemblyId: null,
    initialSelectedTab: null
  }

  // content styles for Equipments and Surfaces when not using renovation view
  contentStyles = {
    paddingTop: '32px'
  }

  componentDidMount = () => {
    const { selectedTab } = this.props
    this.setState({
      initialSelectedTab: selectedTab
    })
  }

  setIsSpaceEquipmentProductAssemblyWidgetOpen = (isSpaceEquipmentProductAssemblyWidgetOpen: boolean): void => {
    this.setState({
      isSpaceEquipmentProductAssemblyWidgetOpen
    })
  }

  setSelectedSpaceEquipmentAssemblyId = (selectedSpaceEquipmentAssemblyId: string): void => {
    this.setState({
      selectedSpaceEquipmentAssemblyId
    })
  }

  propertiesTab(): TVDTab {
    const {
      t,
      activeTab,
      widgetType,
      widgetId,
      spaceId,
      disabled,
      isEstimateLockedToCurrentUser,
      isEstimateFrozen,
      propertiesStoreId,
    } = this.props
    return {
      testId: 'space-properties-tab',
      props: {
        label: t('tabs._PROPERTIES_'),
        disabled: (activeTab !== '' && activeTab !== PROPERTIES_TAB) || disabled
      },
      content: <PropertyContainer
        widgetId={widgetId}
        widgetSize={this.props.widgetSize}
        tab={PROPERTIES_TAB}
        type={widgetType.toLowerCase()}
        resourceId={spaceId}
        propertiesStoreId={propertiesStoreId}
        disabled={disabled || !isEstimateLockedToCurrentUser || isEstimateFrozen}
        actionText={t('userModifiedInfo._SPACES_RESET_DEFAULT_VALUE_')} />
    }
  }

  equipmentsTab(): TVDTab {
    const {
      t,
      activeTab,
      spaceId,
      disabled,
      equipmentsListStoreId,
      widgetId,
      spacesEstimateType
    } = this.props
    return {
      testId: 'space-equipment-tab',
      props: {
        label: t('tabs._EQUIPMENT_'),
        disabled: (activeTab !== '' && activeTab !== EQUIPMENTS_TAB) || disabled
      },
      contentStyles: spacesEstimateType !== viewMode.RENOVATION ? this.contentStyles : undefined,
      content: <Equipments
        widgetId={widgetId}
        widgetSize={this.props.widgetSize}
        tab={EQUIPMENTS_TAB}
        listStoreId={equipmentsListStoreId}
        resourceId={spaceId}
        disabled={disabled} />
    }
  }

  surfacesTab(): TVDTab {
    const {
      activeTab,
      disabled,
      spaceId,
      t,
      surfacesListStoreId,
      widgetId,
      spacesEstimateType,
    } = this.props
    return {
      testId: 'space-surfaces-tab',
      props: {
        label: t('tabs._SURFACES_'),
        disabled: (activeTab !== '' && activeTab !== SURFACES_TAB) || disabled
      },
      contentStyles: spacesEstimateType !== viewMode.RENOVATION ? this.contentStyles : undefined,
      content: (
        <Surfaces
          widgetId={widgetId}
          widgetSize={this.props.widgetSize}
          tab={SURFACES_TAB}
          disabled={disabled}
          spaceId={spaceId}
          listStoreId={surfacesListStoreId} />
      )
    }
  }

  newEquipmentTab(): TVDTab {
    const {
      t,
      activeTab,
      disabled,
      spacesEstimateType,
      spaceId
    } = this.props
    return {
      testId: 'new-space-equipment-tab',
      props: {
        label: t('tabs._EQUIPMENT_'),
        disabled: (activeTab !== '' && (activeTab === EQUIPMENTS_TAB || activeTab === PROPERTIES_TAB || activeTab === SURFACES_TAB)) || disabled
      },
      contentStyles: spacesEstimateType !== viewMode.RENOVATION ? this.contentStyles : undefined,
      content: <SpacesEquipmentTabMFEContainer
        spaceId={spaceId}
        openProductAssemblyWidget={(id: string) => {
          this.setSelectedSpaceEquipmentAssemblyId(id)
          this.setIsSpaceEquipmentProductAssemblyWidgetOpen(true)
        }}
        handleCloseProductAssemblyWidget={(): void => this.setIsSpaceEquipmentProductAssemblyWidgetOpen(false)} />
    }
  }

  newSurfacesTab(): TVDTab {
    const {
      t,
      activeTab,
      disabled,
      spacesEstimateType,
      spaceId
    } = this.props
    return {
      testId: 'new-surfaces-tab',
      props: {
        label: t('tabs._SURFACES_'),
        disabled: (activeTab !== '' && (activeTab === EQUIPMENTS_TAB || activeTab === PROPERTIES_TAB || activeTab === SURFACES_TAB)) || disabled
      },
      contentStyles: spacesEstimateType !== viewMode.RENOVATION ? this.contentStyles : undefined,
      content: <SpaceSurfacesTabMFEContainer spaceId={spaceId} />
    }
  }

  tabs(): Array<TVDTab> {
    const { widgetType } = this.props
    const defaultTabs = [this.propertiesTab(), this.equipmentsTab(), this.surfacesTab()]

    switch (widgetType) {
      case SPACE: {
        if (getIsFeatureEnabledInSet(FEATURE_SPACES_EQUIPMENT_TAB)) {
          defaultTabs.splice(defaultTabs.length - 1, 0, this.newEquipmentTab())
        }
        if (getIsFeatureEnabledInSet(FEATURE_SPACES_SURFACES_TAB)) defaultTabs.push(this.newSurfacesTab())
        return defaultTabs
      }
      case SPACEGROUP: {
        return [this.propertiesTab()]
      }
      default:
        return []
    }
  }

  render(): React$Element<any> | null {
    const {
      classes, spaceId, dispatchSetSelectedTab, widgetType
    } = this.props
    const { initialSelectedTab } = this.state

    return initialSelectedTab !== null ? (
      <div className={classes.root}>
        <div className={classes.contentWrapper}>
          <TabsPlain
            initialSelectedTab={initialSelectedTab}
            tabs={this.tabs()}
            onChange={(selectedTab: string) => {
              dispatchSetSelectedTab(widgetType, selectedTab)
          }} />
          <SpaceEquipmentProductAssemblyMFEContainer
            spaceId={spaceId}
            spaceEquipmentAssemblyId={this.state.selectedSpaceEquipmentAssemblyId}
            isWidgetOpen={this.state.isSpaceEquipmentProductAssemblyWidgetOpen}
            setIsWidgetOpen={(isOpen: boolean): void => {
                  this.setIsSpaceEquipmentProductAssemblyWidgetOpen(isOpen)
                }} />
        </div>
      </div>
    ) : null
  }
}

const mapStateToProps = ({ widgets, app, componentPreferences }: TVDReduxStore, props: Props): Object => {
  const { widgetId } = props
  const {
    activeEdit,
    activeTab,
    isEstimateLockedToCurrentUser,
    isEstimateFrozen,
    spacesEstimateType
  } = app
  const {
    [widgetId]: {
      widgetType,
      disabled
    } = {}
  } = widgets
  const {
    [widgetType?.toLowerCase()]: {
      selectedTab
    } = {}
  } = componentPreferences

  return {
    activeTab,
    activeEdit,
    widgetType,
    disabled: props.disabled || disabled,
    isEstimateLockedToCurrentUser,
    isEstimateFrozen,
    spacesEstimateType,
    selectedTab
  }
}

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  dispatchSetSelectedTab: (widgetType: string, selectedTab: string) => dispatch(setComponentPreferencesSelectedTab(widgetType, selectedTab))
})

export default withTranslation('translations', {})(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Space)))
