// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { loadMFE, registeredMFEs } from '../../../utils/MFEUtils'
import { API_BASE_URL, ALLOW_WITH_CREDENTIALS } from '../../../constants/envConstants'
import { openGenericErrorModal } from '../../../utils/modalUtils'
import { dispatch } from '../../../store'
import { setCalculationActive, setCalculationComplete, setWidgetActive } from '../../../actions/app'
import { postPolling } from '../../../actions/postPolling'
import { enableAllWidgets, setSelectedTab, closeWidget } from '../../../actions/widgets'

type Props = {|
  spaceId: string,
|}
const SpaceSurfacesTabMFEContainer = ({ spaceId }: Props): React$Element<'div'> => {
  const {
    realEstateId,
    accessToken,
    haahtelaApiUserToken,
    userId,
    languageCode,
    selectedAccountId,
    licenseType,
    activeEstimate: activeSpacesEstimate,
    postPollingTS,
    activeEdit,
    activeCalculation,
    estimateType,
    isEstimateLockedToCurrentUser,
    resultViewType
  } = useSelector(({
    activeEstimate,
    app,
    user,
  }: TVDReduxStore): {|
    languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
    selectedAccountId: $PropertyType<TVDApplicationStore, 'selectedAccountId'>,
    accessToken: $PropertyType<TVDUserAuthorization, 'accessToken'>,
    haahtelaApiUserToken: $PropertyType<TVDUserClaims, 'haahtelaApiUserToken'>,
    userId: $PropertyType<TVDUserClaims, 'userId'>,
    licenseType: $PropertyType<TVDApplicationStore, 'licenseType'>,
    activeEstimate: TVDActiveEstimateStore,
    realEstateId: $PropertyType<TVDApplicationStore, 'realEstateId'>,
    postPollingTS: $PropertyType<TVDApplicationStore, 'postPollingTS'>,
    activeEdit: $PropertyType<TVDApplicationStore, 'activeEdit'>,
    activeCalculation: $PropertyType<TVDApplicationStore, 'activeCalculation'>,
    estimateType: $PropertyType<TVDApplicationStore, 'spacesEstimateType'>,
    isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>,
    resultViewType: $PropertyType<TVDApplicationStore, 'spacesResultView'>,
  |} => ({
    languageCode: app.languageCode,
    selectedAccountId: app.selectedAccountId,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    licenseType: app.licenseType,
    activeEstimate,
    realEstateId: app.realEstateId,
    postPollingTS: app.postPollingTS,
    activeEdit: app.activeEdit,
    activeCalculation: app.activeCalculation,
    estimateType: app.spacesEstimateType,
    isEstimateLockedToCurrentUser: app.isEstimateLockedToCurrentUser,
    resultViewType: app.spacesResultView
  }))

  const updatePostPollingTS = () => {
    dispatch(postPolling())
  }

  const dispatchSetCalculationActive = () => {
    dispatch(setCalculationActive())
  }

  const dispatchSetCalculationComplete = () => {
    dispatch(setCalculationComplete())
  }

  const registeredSpacesAPIModule = registeredMFEs['spaces-api-module']

  useEffect(() => {
    if (
      languageCode &&
      accessToken &&
      haahtelaApiUserToken &&
      userId &&
      selectedAccountId &&
      activeSpacesEstimate &&
      realEstateId &&
      estimateType
    ) {
      const dispatchSetSelectedTab = () => {
        dispatch(setSelectedTab('new-surfaces-tab', spaceId))
      }

      dispatchSetSelectedTab()


      const dispatchSetTabActive = (active: boolean) => {
        if (active) {
          dispatch(setWidgetActive(spaceId, 'surfaces-tab'))
        } else {
          dispatch(enableAllWidgets())
        }
      }

      const dispatchCloseWidget = () => {
        dispatch(closeWidget(spaceId))
      }
      const render = (module: TVDMFEFunctionality): void => {
        const moduleRun = module.run || null
        if (moduleRun) {
          moduleRun(
            'renderSurfacesTab',
            [
              'new-surfaces-tab',
              {
                APIBaseURL: API_BASE_URL,
                languageCode,
                accessToken,
                haahtelaApiUserToken,
                userId,
                selectedAccountId,
                openGenericErrorModal,
                ALLOW_WITH_CREDENTIALS,
                realEstateId,
                activeEstimate: activeSpacesEstimate,
                postPollingTS,
                updatePostPollingTS,
                activeEdit,
                activeCalculation,
                estimateType,
                isEstimateLockedToCurrentUser,
                setCalculationActive: dispatchSetCalculationActive,
                setCalculationComplete: dispatchSetCalculationComplete,
                spacesResultView: resultViewType,
                dispatchSetTabActive,
                spaceId,
                dispatchCloseWidget
              }
            ]
          )
        }
      }
      if (registeredSpacesAPIModule) {
        render(registeredSpacesAPIModule)
      } else {
        loadMFE('spaces', 'spaces-api-module', (module: TVDMFEFunctionality): void => {
          render(module)
        })
      }
    }
  }, [
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    selectedAccountId,
    activeSpacesEstimate,
    licenseType,
    realEstateId,
    postPollingTS,
    activeEdit,
    activeCalculation,
    registeredSpacesAPIModule,
    estimateType,
    isEstimateLockedToCurrentUser,
    resultViewType,
    spaceId,
  ])
  return (
    <div id='new-surfaces-tab' style={{ height: '100%' }} />
  )
}

export default SpaceSurfacesTabMFEContainer
