// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

export const SET_COMPONENT_PREFERENCES_SELECTED_TAB = 'SET_COMPONENT_PREFERENCES_SELECTED_TAB'
export const setComponentPreferencesSelectedTab = (componentId: string, selectedTab: string): TVDAction => ({
  type: SET_COMPONENT_PREFERENCES_SELECTED_TAB,
  payload: {
    componentId,
    selectedTab
  }
})

export const REMOVE_COMPONENT_PREFERENCES = 'REMOVE_COMPONENT_PREFERENCES_SELECTED_TAB'
export const removeComponentPreferences = (componentId: string): TVDAction => ({
  type: REMOVE_COMPONENT_PREFERENCES,
  payload: {
    componentId
  }
})

export const REMOVE_ALL_COMPONENT_PREFERENCES = 'REMOVE_ALL_COMPONENT_PREFERENCES'
export const removeAllComponentPreferences = (): TVDAction => ({
  type: REMOVE_ALL_COMPONENT_PREFERENCES,
  payload: {}
})

export const SET_CATEGORY_ROW_OPEN_COMPONENT_PREFERENCE = 'SET_CATEGORY_ROW_OPEN_COMPONENT_PREFERENCE'
export const setCategoryRowOpenComponentPreference = (componentId: string, rowId: string, isOpen: boolean): TVDAction => ({
  type: SET_CATEGORY_ROW_OPEN_COMPONENT_PREFERENCE,
  payload: {
    componentId,
    rowId,
    isOpen
  }
})

export const SET_SCROLL_POSITION_COMPONENT_PREFERENCE = 'SET_SCROLL_POSITION_COMPONENT_PREFERENCE'
export const setScrollPositionComponentPreference = (componentId: string, scrollPosition: number): TVDAction => ({
  type: SET_SCROLL_POSITION_COMPONENT_PREFERENCE,
  payload: {
    componentId,
    scrollPosition,
  }
})

export const SET_X_Y_COMPONENT_PREFERENCE = 'SET_X_Y_COMPONENT_PREFERENCE'
export const setXYComponentPreference = (componentId: string, xy: [number, number]): TVDAction => ({
  type: SET_X_Y_COMPONENT_PREFERENCE,
  payload: {
    componentId,
    xy,
  }
})

export const SET_SIZE_COMPONENT_PREFERENCE = 'SET_SIZE_COMPONENT_PREFERENCE'
export const setSizeComponentPreference = (componentId: string, size: { width: number, height: number }): TVDAction => ({
  type: SET_SIZE_COMPONENT_PREFERENCE,
  payload: {
    componentId,
    size,
  }
})

export const SET_OPEN_ROW_IDS_COMPONENT_PREFERENCE = 'SET_OPEN_ROW_IDS_COMPONENT_PREFERENCE'
export const setOpenRowIdsComponentPreference = (componentId: string, openRowIds: { [key: string]: boolean }): TVDAction => ({
  type: SET_OPEN_ROW_IDS_COMPONENT_PREFERENCE,
  payload: {
    componentId,
    openRowIds,
  }
})

export const SET_SHOW_SPACE_LIST_PROPERTIES_COMPONENT_PREFERENCE = 'SET_SHOW_SPACE_LIST_PROPERTIES_COMPONENT_PREFERENCE'
export const setShowSpaceListPropertiesComponentPreference = (
  componentId: string,
  showSpaceProperties: boolean,
  showSpaceGroupProperties: boolean
): TVDAction => ({
  type: SET_SHOW_SPACE_LIST_PROPERTIES_COMPONENT_PREFERENCE,
  payload: {
    componentId,
    showSpaceProperties,
    showSpaceGroupProperties
  }
})

export const SET_SELECTED_PROPERTIES_COMPONENT_PREFERENCE = 'SET_SELECTED_PROPERTIES_COMPONENT_PREFERENCE'
export const setSelectedPropertiesComponentPreference = (
  componentId: string,
  spaceProperties: string[],
  spaceGroupProperties: string[]
): TVDAction => ({
  type: SET_SELECTED_PROPERTIES_COMPONENT_PREFERENCE,
  payload: {
    componentId,
    spaceProperties,
    spaceGroupProperties
  }
})

