// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { includes, find } from 'lodash'
import { TableRow } from '@material-ui/core'
import { typographyClasses, colors } from 'frontend-assets'
import {
  multiEditContainerTypes,
  rowsWithYearInput,
  STORY_PROPERTY
} from '../../../../../constants/contentTypes'

import Switch from '../../../Switch/Switch'
import InputField from '../../../InputField/InputField'
import InfoPopover from '../../../InfoPopover/InfoPopover'
import UserModifiedIcon from '../../../../containers/infoComponents/UserModifiedIcon'
import DropdownMenu from '../../../menus/DropdownMenu/DropdownMenu'
import MultipleEditInput from '../../../MultipleEditInput/MultipleEditInput'
import ListRowCell from '../../../lists/common/ListRowCell/ListRowCell'
import DescriptionCell from '../../../lists/common/DescriptionCell/DescriptionCell'
import OverflowTooltip from '../../../../common/OverflowTooltip/OverflowTooltip'

const styles = (): Object => ({
  valueCell: {
    color: colors.defaultText,
    border: '0px',
    display: 'flex',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  cell: {
    border: '0',
    color: colors.defaultText,
    fontSize: '14px',
    lineHeight: '20px',
    height: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subRowCell: {
    paddingLeft: '16px'
  },
  categoryTitle: {
    fontSize: '20px'
  },
  propertyRow: {
    ...typographyClasses.bodyDefault,
    color: colors.defaultText,
    overflow: 'hidden',
    height: '40px',
    '&:hover [data-visible_on_hover]': {
      visibility: 'visible',
    },
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
    justifyContent: 'flex-end'
  },
  arrow: {
    marginRight: '5px'
  },
  userModifiedIcon: {
    marginLeft: '10px'
  },
  columnSpannerCell: {
    width: '100%',
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 62,
    marginLeft: 10
  },
  staticValue: {
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    cursor: 'default'
  }
})

type Props = {
  classes: Object, // withstyles classes object
  theme: Object, // withStyles theme object
  row: Object, // Rows data object
  rowText: string, // Description of row
  onBlur: Function, // function that is triggered on onBlur event
  onChange: Function, // function that is triggered when value changes
  resetDefault: (string) => void, // reset property to default value
  parentLevel: number, // hierarchy level of parent categoryrow so propertyrow can count proper padding | if # is negative, uses fixed 40px padding
  disabled: boolean, // flag to disable propertyRow events
  descriptionCellWidth: Number, // custom width for description cell
  inputFieldProps: Object, // vehicle to pass properties to inputField component
  resourceId: string, // widget resourceId
  widgetType: string, // widget type
  placeholder: string, // placeholder for input
  actionText?: string, // optional actionText for userModifiedIcon to overwrite default text
  isSubRow: boolean, // indicates if row is a subrow
  paddingLeftRow: number, // custom padding for propertyRow
  staticValue: boolean, // should the inputs be static instead of modifiable (set where PropertyContainer is called)
  onPropertyRowMount?: (row: TVDPropertiesListItem) => void, // optional cb for when row mounts
}

export class PropertyRow extends Component<Props> {
  static defaultProps = {
    index: 0,
    actionText: '',
    paddingLeftRow: 40,
    onPropertyRowMount: undefined
  }

  componentDidMount = () => {
    const { onPropertyRowMount, row } = this.props
    if (onPropertyRowMount) onPropertyRowMount(row)
  }

  resetDefault = () => {
    const { row } = this.props
    this.props.onBlur(row.propertyName, row.defaultValue, true)
    this.props.resetDefault(row.propertyName)
  }

  checkIfMultiEdit(): boolean {
    const { widgetType } = this.props
    if (Object.prototype.hasOwnProperty.call(multiEditContainerTypes, widgetType)) return true
    return false
  }

  getPropertyRow(): React$Element<any> {
    const {
      classes,
      row,
      rowText,
      parentLevel,
      resourceId,
      widgetType,
      actionText,
      isSubRow,
      paddingLeftRow
    } = this.props

    let paddingLeft = paddingLeftRow
    if (parentLevel >= 0) paddingLeft = (parentLevel * 15) + 68

    const {
      _links: {
        info: {
          href: linksInfoHref
        } = {}
      } = {}
    } = row

    const description = isSubRow ? rowText.replace('* ', '') : rowText
    const cellStyles = isSubRow ? `${classes.cell} ${classes.subRowCell}` : classes.cell

    const descriptionCell = (
      <ListRowCell>
        <div style={{ paddingLeft }} title={row.localizedName}>
          <div className={cellStyles} title={row.localizedName}>
            <DescriptionCell data-testid='property-row-description' text={description} />
          </div>
        </div>
      </ListRowCell>
    )

    const inputCell = (
      <ListRowCell>
        <div className={classes.inputWrapper}>
          <div className={classes.valueCell}>
            {this.getInput()}
          </div>
          <div className={classes.iconsContainer}>
            {
              (!!linksInfoHref || widgetType === STORY_PROPERTY) &&
              <div data-visible_on_hover className={classes.infoLink}>
                <InfoPopover
                  infoData={{
                    id: resourceId,
                    type: widgetType,
                    propertyName: row.propertyName,
                    heading: row.localizedName
                  }}
                  id={`${row.propertyName}-InfoPopover`} />
              </div>
            }
            {
              row.userModified &&
              <div className={classes.userModifiedIcon}>
                { !this.checkIfMultiEdit() && // hide usermodifiedIcon from multiedit widget for now because feature requires planning
                  <UserModifiedIcon
                    id={row.propertyName}
                    defaultValue={this.getRowDefaultValue()}
                    actionCb={this.resetDefault}
                    dataType={row.dataType}
                    actionText={actionText} />
                }
              </div>
            }
          </div>
        </div>
      </ListRowCell>
    )

    return (
      <TableRow className={classes.propertyRow}>
        {descriptionCell}
        {inputCell}
        <ListRowCell className={classes.columnSpannerCell} />
      </TableRow>
    )
  }

  getRowDefaultValue(): string {
    const { row } = this.props
    if (row.userModified && row.dataType === 'enum' && row.options?.length) {
      const defaultOption = find(row.options, (option: Object) => option.value === row.defaultValue)
      return defaultOption.localizedName
    }
    return row.defaultValue
  }

  getInput(): React$Element<any> {
    const {
      classes,
      onBlur,
      onChange,
      disabled,
      inputFieldProps,
      row
    } = this.props
    // 4.10.2019 datatypes are in the process of being unified to 'boolean', 'number', 'enum', 'string'
    // however old ones still exist in places so until they're 100% certified changed, this switch case
    // will ensure all possibilities work
    const dataTypeIsYear = includes(rowsWithYearInput, row.propertyName)

    if (this.checkIfMultiEdit()) {
      return (
        <MultipleEditInput
          dataType={dataTypeIsYear ? 'year' : row.dataType}
          onChange={(selection: any) => onChange(row.propertyName, selection)}
          placeholderText={row.placeholder}
          booleanTooltipText={row.booleanTooltipText}
          multiselectedItems={row.values || [row.value]}
          value={row.value}
          items={row.options}
          unit={row.unit} />
      )
    }
    if (this.props.staticValue) {
      return (
        <div className={classes.staticValue}>
          <OverflowTooltip tooltipText={row.valueLocalization}>
            { row.valueLocalization }
          </OverflowTooltip>
        </div>
      )
    }

    switch (row.dataType) {
      case 'bool':
      case 'boolean':
        return (
          <Switch
            onChange={(value: boolean) => onBlur(row.propertyName, value)}
            checked={row.value}
            disabled={disabled}
            testId={`Property-${row.propertyName}`} />
        )
      case 'enum':
        return (
          <DropdownMenu
            minimalist
            width='L'
            onChange={(value: string) => onChange(row.propertyName, value)}
            items={row.options || []}
            defaultValue={row.value}
            disabled={disabled}
            id={`Property-${row.propertyName}`} />
        )
      case 'double':
      case 'string':
      case 'int':
      case 'integer':
      case 'number':
        return (
          <InputField
            disabled={disabled}
            dataType={dataTypeIsYear ? 'year' : row.dataType}
            width={220}
            onChange={(value: string | number) => onBlur(row.propertyName, value)}
            initialValue={row.value}
            id={`Property-${row.propertyName}`}
            unit={row.unit}
            {...inputFieldProps} />
        )
      default:
        return <div id='erroneous-dataType'>{row.dataType}</div>
    }
  }

  render(): React$Element<any> {
    return this.getPropertyRow()
  }
}

export default withStyles(styles, { withTheme: true })(PropertyRow)
