// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { loadMFE, registeredMFEs } from '../../../utils/MFEUtils'
import { API_BASE_URL, ALLOW_WITH_CREDENTIALS } from '../../../constants/envConstants'
import { openGenericErrorModal } from '../../../utils/modalUtils'
import { setCalculation, setEstimateName } from '../../../actions/app'
import { dispatch, history } from '../../../store'
import { getEstimateHistoryObj } from '../../../utils/urlUtil'
import { ELEMENTS } from '../../../constants/moduleConstants'

type Props = {|
  handleCloseModal: Function,
  isOpen: boolean
|}

export const buildingElementsEstManMFERootId = 'building-elements-estimate-management'

const BuildingElementsEstManMFEContainer = ({
  handleCloseModal,
  isOpen
}: Props): null => {
  const {
    realEstateId,
    accessToken,
    haahtelaApiUserToken,
    userId,
    languageCode,
    selectedAccountId,
    activeEstimate: activeBuildingElementsEstimate,
    postPollingTS,
    isEstimateLockedToCurrentUser,
    buildingId,
    realEstateName
  } = useSelector(({
    activeEstimate,
    app,
    user,
  }: TVDReduxStore): {|
    languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
    selectedAccountId: $PropertyType<TVDApplicationStore, 'selectedAccountId'>,
    accessToken: $PropertyType<TVDUserAuthorization, 'accessToken'>,
    haahtelaApiUserToken: $PropertyType<TVDUserClaims, 'haahtelaApiUserToken'>,
    userId: $PropertyType<TVDUserClaims, 'userId'>,
    activeEstimate: TVDActiveEstimateStore,
    realEstateId: $PropertyType<TVDApplicationStore, 'realEstateId'>,
    postPollingTS: $PropertyType<TVDApplicationStore, 'postPollingTS'>,
    isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>,
    buildingId: $PropertyType<TVDApplicationStore, 'buildingId'>,
    realEstateName: $PropertyType<TVDApplicationStore, 'realEstateName'>,
  |} => ({
    languageCode: app.languageCode,
    selectedAccountId: app.selectedAccountId,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    activeEstimate,
    realEstateId: app.realEstateId,
    postPollingTS: app.postPollingTS,
    isEstimateLockedToCurrentUser: app.isEstimateLockedToCurrentUser,
    buildingId: app.buildingId,
    realEstateName: app.realEstateName
  }))

  const estimateId = activeBuildingElementsEstimate?.id
  const registeredEstimateManagementAPIModule = registeredMFEs['building-elements-api-module']

  useEffect(() => {
    if (
      languageCode &&
      accessToken &&
      haahtelaApiUserToken &&
      userId &&
      selectedAccountId &&
      estimateId &&
      realEstateId &&
      handleCloseModal &&
      buildingId &&
      !!document.getElementById(buildingElementsEstManMFERootId)
    ) {
      const navigateToEstimate = (id: string, description: string) => {
        if (id && description && selectedAccountId) {
          dispatch(setCalculation(id))
          dispatch(setEstimateName(description))
          handleCloseModal()

          const newHistory = getEstimateHistoryObj({
            app: ELEMENTS.toUpperCase(),
            accountId: selectedAccountId,
            realEstateId,
            estimateId: id,
            realEstateName
          })
          history.push(newHistory)
        }
      }
      const render = (module: TVDMFEFunctionality): void => {
        const moduleRun = module.run || null
        if (moduleRun) {
          moduleRun(
            'renderBuildingElementsEstimateManagement',
            [
              buildingElementsEstManMFERootId,
              {
                APIBaseURL: API_BASE_URL,
                languageCode,
                accessToken,
                haahtelaApiUserToken,
                userId,
                selectedAccountId,
                openGenericErrorModal,
                ALLOW_WITH_CREDENTIALS,
                realEstateId,
                estimateId,
                isEstimateLockedToCurrentUser,
                handleCloseModal,
                isOpen,
                buildingId,
                navigateToEstimate
              }
            ]
          )
        }
      }
      if (registeredEstimateManagementAPIModule) {
        render(registeredEstimateManagementAPIModule)
      } else {
        loadMFE('building_elements', 'building-elements-api-module', (module: TVDMFEFunctionality): void => {
          render(module)
        })
      }
    }
  }, [
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    selectedAccountId,
    estimateId,
    realEstateId,
    postPollingTS,
    registeredEstimateManagementAPIModule,
    isEstimateLockedToCurrentUser,
    handleCloseModal,
    isOpen,
    buildingId,
    realEstateName
  ])
  return null
}

export default BuildingElementsEstManMFEContainer

