// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { useEffect, useState, useRef } from 'react'
import { useSelector, useStore, batch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import qs from 'query-string'
import {
  setSelectedAccount,
  setCalculation,
  setRealEstateId,
  setApplication,
  setBuildingId,
  setSiteId,
  setRealEstateName,
  storePrimaryEstimatesForModules,
  setEstimateName,
  setIsEstimateFrozen,
  storeRealEstateSimulationResults,
  storeRealEstateModuleFeatures,
  setLicenseType,
  setIsEstimateLockedToCurrentUser,
  setIndexNumberOnAssessmentDate
} from '../../../actions/app'
import { setActiveEstimate, resetActiveEstimate } from '../../../actions/activeEstimate'
import { setActiveRealEstate } from '../../../actions/activeRealEstate'
import { saveUsersGroups } from '../../../actions/user'
import { TVD_TOKEN_USER_TYPE_USER } from '../../../constants/apiConstants'
import {
  ELEMENTS,
  SPACES,
  WOP,
  LOCAN,
  REAL_ESTATES,
  PROFITABILITY,
  PROCUREMENT_ELEMENTS,
  RENOVATION_PROGRAM,
  USAGE_AND_MAINTENANCE,
  SCENARIO_USE
} from '../../../constants/moduleConstants'
import {
  getRealEstatesWithRealEstateIdRequest,
  getBuildingsWithBuildingIdRequest as getBuildingsWithBuildingIdRequestRealestate,
  getSitesWithSiteIdRequest,
  getRealEstatesSimulationResultsWithRealEstateIdRequest,
  getRealEstatesModuleFeaturesWithRealEstateIdRequest,
  getRealEstatesModulesWithRealEstateIdRequest
} from '../../../utils/generated-api-requests/realestate'
import HALParser from '../../../utils/HALParser'
import { getLastPathPart } from '../../../utils/urlUtil'

import {
  putEstimateLockWithEstimateIdRequest as putEstimateLockWithEstimateIdRequestElements,
  deleteEstimateLockWithEstimateIdRequest as deleteEstimateLockWithEstimateIdRequestElements,
} from '../../../utils/generated-api-requests/buildingelements'
import {
  putEstimateLockWithEstimateIdRequest as putEstimateLockWithEstimateIdRequestWop,
  deleteEstimateLockWithEstimateIdRequest as deleteEstimateLockWithEstimateIdRequestWop
} from '../../../utils/generated-api-requests/wop'
import {
  putEstimateLockWithEstimateIdRequest as putEstimateLockWithEstimateIdRequestLocan,
  deleteEstimateLockWithEstimateIdRequest as deleteEstimateLockWithEstimateIdRequestLocan
} from '../../../utils/generated-api-requests/locan'
import {
  putEstimateLockWithEstimateIdRequest as putEstimateLockWithEstimateIdRequestSpaces,
  deleteEstimateLockWithEstimateIdRequest as deleteEstimateLockWithEstimateIdRequestSpaces
} from '../../../utils/generated-api-requests/spaces'
import { clearWidgets } from '../../../actions/widgets'
import {
  getEstimatesWithEstimateIdRequest,
  deleteEstimatesLockWithEstimateIdRequest,
  putEstimatesLockWithEstimateIdRequest,
} from '../../../utils/generated-api-requests/estimates'
import {
  getEstimateWithEstimateIdRequest as getEstimateWithEstimateIdRequestUsageMaintenance,
} from '../../../utils/generated-api-requests/usage-maintenance'
import {
  getEstimateWithEstimateIdRequest as getEstimateWithEstimateIdRequestReno
} from '../../../utils/generated-api-requests/renovation-program'
import { type TVDParserParameters } from '../../../utils/parserMapper'

const prepareEstimate = ({ module }: Object, setupDoneCb?: Function, onLockPUTFailCb?: (response: TVDRequestResponse) => void) => {
  const cb = (estimate: TVDCalculation) => {
    if (setupDoneCb) {
      setupDoneCb({
        estimate,
      })
    }
  }

  switch (module) {
    case SPACES: {
      putEstimateLockWithEstimateIdRequestSpaces(
        { body: {} },
        {},
        () => { getEstimatesWithEstimateIdRequest({}, cb) },
        ({ response }: TVDErrorResponse) => {
          if (onLockPUTFailCb) { onLockPUTFailCb(response) }
          getEstimatesWithEstimateIdRequest({}, cb)
        },
        { disableErrorMessageCheck: true, disableRefreshEstimateLock: true }
      )
      break
    }
    case ELEMENTS: {
      putEstimateLockWithEstimateIdRequestElements(
        { body: {} },
        {},
        () => { getEstimatesWithEstimateIdRequest({}, cb) },
        ({ response }: TVDErrorResponse) => {
          if (onLockPUTFailCb) { onLockPUTFailCb(response) }
          getEstimatesWithEstimateIdRequest({}, cb)
        },
        { disableErrorMessageCheck: true, disableRefreshEstimateLock: true }
      )
      break
    }
    case WOP: {
      putEstimateLockWithEstimateIdRequestWop(
        { body: {} },
        {},
        () => { getEstimatesWithEstimateIdRequest({}, cb) },
        ({ response }: TVDErrorResponse) => {
          if (onLockPUTFailCb) { onLockPUTFailCb(response) }
          getEstimatesWithEstimateIdRequest({}, cb)
        },
        { disableErrorMessageCheck: true, disableRefreshEstimateLock: true }
      )
      break
    }
    case LOCAN: {
      putEstimateLockWithEstimateIdRequestLocan(
        { body: {} },
        {},
        () => { getEstimatesWithEstimateIdRequest({}, cb) },
        ({ response }: TVDErrorResponse) => {
          if (onLockPUTFailCb) { onLockPUTFailCb(response) }
          getEstimatesWithEstimateIdRequest({}, cb)
        },
        { disableErrorMessageCheck: true, disableRefreshEstimateLock: true }
      )
      break
    }
    case REAL_ESTATES: {
      break
    }

    case USAGE_AND_MAINTENANCE:
    case PROCUREMENT_ELEMENTS:
    case PROFITABILITY: {
      putEstimatesLockWithEstimateIdRequest(
        { body: {} },
        {},
        () => {
          if (module === USAGE_AND_MAINTENANCE) {
            getEstimateWithEstimateIdRequestUsageMaintenance({}, cb)
          } else {
            getEstimatesWithEstimateIdRequest({}, cb)
          }
        },
        ({ response }: TVDErrorResponse) => {
          if (onLockPUTFailCb) { onLockPUTFailCb(response) }
          if (module === USAGE_AND_MAINTENANCE) {
            getEstimateWithEstimateIdRequestUsageMaintenance({}, cb)
          } else {
            getEstimatesWithEstimateIdRequest({}, cb)
          }
        },
        { disableErrorMessageCheck: true, disableRefreshEstimateLock: true }
      )
      break
    }
    case RENOVATION_PROGRAM: {
      putEstimatesLockWithEstimateIdRequest(
        { body: {} },
        {},
        () => { getEstimateWithEstimateIdRequestReno({}, cb) },
        ({ response }: TVDErrorResponse) => {
          if (onLockPUTFailCb) { onLockPUTFailCb(response) }
          getEstimateWithEstimateIdRequestReno({}, cb)
        },
        { disableErrorMessageCheck: true, disableRefreshEstimateLock: true }
      )
      break
    }

    default: {
      break
    }
  }
}

const prepareRealEstate = ({ realEstateId }: Object, cb: Function) => {
  let realEstateName = ''
  let buildingId = ''
  let siteId = ''
  let primaryEstimates = {}
  let activeRealEstate = null
  let licenseType = ''

  const getRealEstate = (): Promise<void> => new Promise((
    resolve: () => void,
    reject: () => void
  ) => {
    getRealEstatesWithRealEstateIdRequest(
      { path: { realEstateId } },
      {},
      (realEstate: {|
          description: string,
          licenseType: string,
          nonEmbedded: { HALParsedData: $PropertyType<TVDParserParameters, 'HALParsedData'> }
      |}) => {
        const {
          description: realEstateDescription,
          licenseType: realEstateLicenseType
        } = realEstate
        realEstateName = realEstateDescription
        licenseType = realEstateLicenseType
        const {
          site: {
            href: realEstateSiteHref
          },
          building: {
            href: buildingHref
          }
        } = HALParser.getLinks(realEstate.nonEmbedded.HALParsedData)
        siteId = getLastPathPart(realEstateSiteHref)
        buildingId = getLastPathPart(buildingHref)

        getBuildingsWithBuildingIdRequestRealestate(
          { path: { buildingId } }, {}, ({ nonEmbedded }: any) => {
            const {
              primaryAbeEstimate,
              primaryBuildingElementsEstimate,
              primarySpacesEstimate,
              primaryProfitabilityEstimate,
              primaryProcurementElementsEstimate,
              primaryRenovationProgramEstimate,
              primaryUsageMaintenanceEstimate
            } = HALParser.getLinks(nonEmbedded)

            const updatedPrimaryEstimates = {
              [ELEMENTS]: primaryBuildingElementsEstimate,
              [SPACES]: primarySpacesEstimate,
              [WOP]: primaryAbeEstimate,
              [PROFITABILITY]: primaryProfitabilityEstimate,
              [PROCUREMENT_ELEMENTS]: primaryProcurementElementsEstimate,
              [RENOVATION_PROGRAM]: primaryRenovationProgramEstimate,
              [USAGE_AND_MAINTENANCE]: primaryUsageMaintenanceEstimate
            }

            const resolvedPrimaryEstimates = Object.keys(updatedPrimaryEstimates)
              .reduce((result: { [moduleName: string]: string }, moduleName: string) => {
                if (!updatedPrimaryEstimates[moduleName]) {
                  console.error(`No primary estimate from module ${moduleName}`)
                  return result
                }
                return ({
                  ...result,
                  [moduleName]: getLastPathPart(updatedPrimaryEstimates[moduleName]?.href)
                })
              }, {})

            getSitesWithSiteIdRequest({ path: { siteId } }, {}, (site: any) => {
              const {
                primaryLocanEstimate: { href: primaryLocanEstimateHref }
              } = HALParser.getLinks(site.nonEmbedded.HALParsedData)
              const primaryLocanEstimateId = getLastPathPart(primaryLocanEstimateHref)
              resolvedPrimaryEstimates[LOCAN] = primaryLocanEstimateId
              primaryEstimates = resolvedPrimaryEstimates
              activeRealEstate = realEstate
              resolve()
            })
          },
          () => { reject() }
        )
      },
      () => { reject() }
    )
  })
  Promise.all([
    getRealEstate(),
    getRealEstatesSimulationResultsWithRealEstateIdRequest({
      path: {
        realEstateId
      }
    }),
    getRealEstatesModuleFeaturesWithRealEstateIdRequest({
      path: {
        realEstateId
      }
    })
  ]).then((res: [
    void,
    {
      [key: string]: { id: number, localizedName: string }
    },
    { [key: number | string]: { id: number } }
  ]): void => {
    const [, realEstateSimulationResults, realEstateModuleFeatures] = res
    cb({
      buildingId,
      siteId,
      realEstateName,
      licenseType,
      primaryEstimates,
      activeRealEstate,
      realEstateSimulationResults: Object.keys(realEstateSimulationResults).map((key: string): number => realEstateSimulationResults[key].id),
      realEstateModuleFeatures: Object.keys(realEstateModuleFeatures)
        .reduce((ids: number[], key: string | number): number[] => {
          const { id } = realEstateModuleFeatures[key]
          return id ? [...ids, id] : ids
        }, [])
    })
  })
}

export const useAppInitialization = ({ module }: Object) => {
  const [isInitialized, setInitialized] = useState(false)
  const [realEstateStatus, setRealEstateStatus] = useState(null)
  const [estimateStatus, setEstimateStatus] = useState(null)
  const [realEstateAndSiteData, setRealEstateAndSiteData] = useState({})
  const [hasInitializedModule, setHasInitializedModule] = useState(null)
  const [moduleIdsStatus, setModuleIdStatus] = useState(null)
  const [lockPUTFailResponse, setLockPUTFailResponse] = useState<TVDRequestResponse | null>(null)
  const prevModule = useRef(null)
  const prevEstimateId = useRef(null)
  const { dispatch } = useStore()
  const {
    app: {
      userType,
      isEstimateLockedToCurrentUser,
      moduleIds,
      selectedAccountId,
      calculation
    },
    user: {
      accounts,
      userGroupTypeIds = []
    }
  } = useSelector((store: TVDReduxStore): TVDReduxStore => store)
  const {
    location: {
      search
    },
  } = useHistory()

  const {
    accountId,
    realEstateId,
    estimateId
  } = qs.parse(search)

  const selectedAccountEnum = accounts.find((account: TVDEnum): boolean => account.value === accountId) || null

  useEffect(() => {
    if (prevEstimateId.current && prevEstimateId.current !== estimateId && isEstimateLockedToCurrentUser) {
      const args = [{}, null, null, { estimateId: prevEstimateId.current }]
      switch (prevModule.current) {
        case SPACES: {
          deleteEstimateLockWithEstimateIdRequestSpaces(...args)
          break
        }
        case ELEMENTS: {
          deleteEstimateLockWithEstimateIdRequestElements(...args)
          break
        }
        case WOP: {
          deleteEstimateLockWithEstimateIdRequestWop(...args)
          break
        }
        case LOCAN: {
          deleteEstimateLockWithEstimateIdRequestLocan(...args)
          break
        }
        case USAGE_AND_MAINTENANCE:
        case RENOVATION_PROGRAM:
        case PROCUREMENT_ELEMENTS:
        case PROFITABILITY: {
          deleteEstimatesLockWithEstimateIdRequest(...args)
          break
        }
        default: {
          break
        }
      }
    }
  }, [
    calculation,
    estimateId,
    isEstimateLockedToCurrentUser,
    prevModule
  ])

  // resetting module specific details when estimate changes
  useEffect(() => {
    batch(() => {
      dispatch(resetActiveEstimate())
      dispatch(clearWidgets())
      dispatch(setCalculation(estimateId))
    })
    setHasInitializedModule(null)
    setEstimateStatus(null)
  }, [dispatch, estimateId])


  // we set estimate and module initializations to be ok if navigating to real estates module aka summary page
  useEffect(() => {
    if (
      (module === REAL_ESTATES || module === SCENARIO_USE) &&
      selectedAccountId
    ) {
      setEstimateStatus('ready')
      setHasInitializedModule(true)
      dispatch(setIsEstimateLockedToCurrentUser(false))
      dispatch(setApplication(module))
    }
  }, [module, selectedAccountId, dispatch])

  // setting selected account enum to store from url
  useEffect(() => {
    if (selectedAccountEnum) {
      dispatch(setSelectedAccount(selectedAccountEnum))
    }
  }, [selectedAccountEnum, dispatch])

  const isReadyToPrepareEstimate = !!(
    !!calculation &&
    estimateStatus === null &&
    hasInitializedModule === null &&
    module !== REAL_ESTATES &&
    module !== SCENARIO_USE
  )

  useEffect(() => {
    if (isReadyToPrepareEstimate) {
      setLockPUTFailResponse(null)
      dispatch(setIsEstimateLockedToCurrentUser(false))
      setHasInitializedModule(false)
      setEstimateStatus('loading')
      prepareEstimate({ module }, ({ estimate: preparedEstimate }: Object) => {
        // removing parser provided extras to have only the actual estimate
        const { singleParser, nonEmbedded, ...restOfPreparedEstimate } = preparedEstimate
        batch(() => {
          dispatch(setActiveEstimate(restOfPreparedEstimate))
          dispatch(setBuildingId(preparedEstimate.buildingId))
          dispatch(setEstimateName(preparedEstimate.description))
          dispatch(setIndexNumberOnAssessmentDate(preparedEstimate.indexNumberOnAssessmentDate))
          dispatch(setIsEstimateFrozen(preparedEstimate.frozen))
          dispatch(setApplication(module))
        })
        setEstimateStatus('ready')
        setHasInitializedModule(true)
      }, (response: TVDRequestResponse) => {
        setLockPUTFailResponse(response)
        dispatch(setIsEstimateLockedToCurrentUser(false))
      })
    }
  }, [
    module,
    isReadyToPrepareEstimate,
    dispatch,
    estimateId
  ])

  useEffect(() => {
    if (realEstateStatus === null && selectedAccountId && realEstateId) {
      setRealEstateStatus('loading')
      dispatch(setRealEstateId(realEstateId))
      prepareRealEstate({ realEstateId, accountId }, (preparedRealEstateAndSiteData: Object) => {
        setRealEstateAndSiteData(preparedRealEstateAndSiteData)
        setRealEstateStatus('ready')
      })
    }

    if (moduleIdsStatus === null && selectedAccountId && realEstateId) {
      setModuleIdStatus('loading')
      getRealEstatesModulesWithRealEstateIdRequest({ path: { realEstateId } }, {}, () => {
        setModuleIdStatus('ready')
      })
    }

    if (
      estimateStatus === 'ready' &&
      realEstateStatus === 'ready' &&
      moduleIdsStatus === 'ready' &&
      !isInitialized
    ) {
      const {
        siteId,
        realEstateName,
        primaryEstimates,
        realEstateSimulationResults,
        realEstateModuleFeatures,
        licenseType,
        buildingId,
        activeRealEstate
      } = realEstateAndSiteData
      batch(() => {
        if (userType === TVD_TOKEN_USER_TYPE_USER && userGroupTypeIds.length === 0) dispatch(saveUsersGroups())
        dispatch(storeRealEstateSimulationResults(realEstateSimulationResults))
        dispatch(storeRealEstateModuleFeatures(realEstateModuleFeatures))
        dispatch(setRealEstateName(realEstateName))
        dispatch(setApplication(module))
        dispatch(setSiteId(siteId))
        dispatch(setBuildingId(buildingId))
        dispatch(storePrimaryEstimatesForModules(primaryEstimates))
        dispatch(setLicenseType(licenseType))
        dispatch(setActiveRealEstate(activeRealEstate))
      })
      setInitialized(true)
    }
  }, [
    realEstateStatus,
    estimateStatus,
    dispatch,
    module,
    realEstateAndSiteData,
    realEstateId,
    userGroupTypeIds.length,
    userType,
    accountId,
    isInitialized,
    moduleIds,
    setModuleIdStatus,
    moduleIdsStatus,
    selectedAccountEnum,
    selectedAccountId,
    isEstimateLockedToCurrentUser,
  ])

  const isLoaded = isInitialized && hasInitializedModule && (prevModule.current === module)

  useEffect(() => {
    if (hasInitializedModule) {
      prevModule.current = module
      prevEstimateId.current = estimateId
    }
  }, [
    hasInitializedModule,
    module,
    estimateId
  ])

  return [isLoaded, lockPUTFailResponse]
}
