// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

/**
 * FEATURE NAMING CONVENTIONS & GUIDELINES
 * Always prefix constants with FEATURE_
 * A feature can include a module, widget and/or the elements that enable them
 * Modules are always in the form of FEATURE_[MODULE_CONSTANT_NAME]_MODULE
 */

import { ELEMENTS, SPACES } from './moduleConstants'
import {
  OTHER_SPACES,
  UPDATE_TECHNICAL_BUILDING_SERVICES,
  USE_DESIGN_MODEL_DEFAULTS,
  UPDATE_PROTECTED_CIRCULATION,
  UPDATE_STRUCTURES,
} from './widgetKeys'

/**
 *  All features that are togglable
 */
export const FEATURE_ELEMENTS_MODULE = ELEMENTS
export const FEATURE_SPACES_MODULE = SPACES
export const FEATURE_OTHER_SPACES = OTHER_SPACES
export const FEATURE_UPDATE_TECHNICAL_BUILDING_SERVICES = UPDATE_TECHNICAL_BUILDING_SERVICES
export const FEATURE_USE_DESIGN_MODEL_DEFAULTS = USE_DESIGN_MODEL_DEFAULTS
export const FEATURE_UPDATE_PROTECTED_CIRCULATION = UPDATE_PROTECTED_CIRCULATION
export const FEATURE_UPDATE_STRUCTURES = UPDATE_STRUCTURES
export const FEATURE_HLC_ROW_CHECKBOXES = 'FEATURE_HLC_ROW_CHECKBOXES'
export const FEATURE_BUILDING_MODAL_CHECKBOXES = 'FEATURE_BUILDING_MODAL_CHECKBOXES'
export const FEATURE_SETTINGS = 'FEATURE_SETTINGS'
export const FEATURE_SHOW_INSTRUCTIONS = 'FEATURE_SHOW_INSTRUCTIONS'
export const FEATURE_TERMS_OF_USE = 'FEATURE_TERMS_OF_USE'
export const FEATURE_RESET_SURFACES = 'FEATURE_RESET_SURFACES'
export const FEATURE_RESET_EQUIPMENTS = 'FEATURE_RESET_EQUIPMENTS'
export const FEATURE_LANGUAGE_SELECTION = 'FEATURE_LANGUAGE_SELECTION'
export const FEATURE_DESIGN_MODEL_MANUAL_FEEDING = 'FEATURE_DESIGN_MODEL_MANUAL_FEEDING'
export const FEATURE_CALCULATION_INFORMATION_USER_RIGHTS_TAB = 'FEATURE_CALCULATION_INFORMATION_USER_RIGHTS_TAB'
export const ELEMENT_INFO_POPOVER = 'ELEMENT_INFO_POPOVER'
export const BUILDING_ELEMENTS_SCHEDULE_CHANGE = 'BUILDING_ELEMENTS_SCHEDULE_CHANGE'
export const ELEMENT_INPUTS = 'ELEMENT_INPUTS'
export const ASSEMBLY_ATTRIBUTES = 'ASSEMBLY_ATTRIBUTES'
export const FEATURE_FUNCTIONS_MODULE = 'FEATURE_FUNCTIONS_MODULE'
export const FEATURE_AREA_MODULE = 'FEATURE_AREA_MODULE'
export const FEATURE_PRODUCTION_DIVISION_MODULE = 'FEATURE_PRODUCTION_DIVISION_MODULE'
export const FEATURE_REAL_ESTATE_MANAGEMENT_MODULE = 'FEATURE_REAL_ESTATE_MANAGEMENT_MODULE'
export const FEATURE_ACCOUNT_MANAGEMENT_MODULE = 'FEATURE_ACCOUNT_MANAGEMENT_MODULE'
export const FEATURE_ADMIN_MODULE = 'FEATURE_ADMIN_MODULE'
export const FEATURE_BUILDINGS_ACCESS_RIGHTS = 'FEATURE_BUILDINGS_ACCESS_RIGHTS'
export const FEATURE_ESTIMATES_ACCESS_RIGHTS = 'FEATURE_ESTIMATES_ACCESS_RIGHTS'
export const FEATURE_IS_ESTIMATE_PRIVATE_CHECKBOX = 'FEATURE_IS_ESTIMATE_PRIVATE_CHECKBOX'
export const FEATURE_LICENCE_TYPE_CHIP = 'FEATURE_LICENCE_TYPE_CHIP'
export const FEATURE_LOCATION_ANALYSIS_MFE = 'FEATURE_LOCATION_ANALYSIS_MFE'
export const FEATURE_BUILDING_ELEMENTS_TASKS_WIDGET_MFE = 'FEATURE_BUILDING_ELEMENTS_TASKS_WIDGET_MFE'
export const FEATURE_SPACES_LIST_MFE = 'FEATURE_SPACES_LIST_MFE'
export const FEATURE_BUILDING_ELEMENTS_SCHEDULE_WIDGET_MFE = 'FEATURE_BUILDING_ELEMENTS_SCHEDULE_WIDGET_MFE'
export const FEATURE_BE_MFE_ESTMAN = 'FEATURE_BE_MFE_ESTMAN'
export const FEATURE_SITE_EQUIPMENT_TAB = 'FEATURE_SITE_EQUIPMENT_TAB'
export const FEATURE_SCENARIO_RENOVATION_USE = 'FEATURE_SCENARIO_RENOVATION_USE'
export const FEATURE_NEW_PROCUREMENT_ELEMENTS = 'FEATURE_NEW_PROCUREMENT_ELEMENTS'
export const FEATURE_SPACES_EQUIPMENT_TAB = 'FEATURE_SPACES_EQUIPMENT_TAB'
export const FEATURE_SPACES_SURFACES_TAB = 'FEATURE_SPACES_SURFACES_TAB'
export const FEATURE_SCENARIO_LCA = 'FEATURE_SCENARIO_LCA'
