// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import * as React from 'react'
import { connect } from 'react-redux'
import * as qs from 'query-string'
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Spinner from '../../common/Spinner/Spinner'

import {
  getAccessToken,
  getAuthServiceApiToken,
  introspectAccessToken,
  saveRoute,
  saveNameId,
  saveSessionIndex,
  saveUserClaims,
  saveAuthorizationData,
  saveAuthServiceApiToken,
  isAuthenticatedUser,
} from '../../../actions/user'

import {
  getUsersSelfRequest,
  getUsersSelfAccountsRequest,
  getUsersSelfLegalConsentIsUpdateRequiredRequest
} from '../../../utils/generated-api-requests/users'
import { getJWTTokenDecoded } from '../../../utils/requests/jwt'
import { getUserTypeFromPathname, getUsersAPIBasePath, getUserPrivilegeFilter } from '../../../utils/apiUtils'
import { getAuthUrlByUserType } from '../../../utils/authUtil'
import { initWSConnection } from '../../../utils/websocketUtils'
import { setUserType } from '../../../actions/app'

type DispatchProps = {|
  dispatchSetUserType: (userType: TVDTokenUserType) => void, // set user type to Store
  saveAuthorizationData: Function,
  saveAuthServiceApiToken: Function,
  saveRoute: Function,
  saveNameId: Function,
  saveSessionIndex: Function,
  saveUserClaims: Function,
|}

type Props = {|
  location: Object,
  user: Object,
  ...DispatchProps,
  legalConsentUpdateRequired: $PropertyType<TVDApplicationStore, 'legalConsentUpdateRequired'>
|}

type State = {|
  WSConnectionCreated: boolean, // if the web socket connection has been succesfully created
|}

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: '#f5f7fa'
  },
}

export class Auth extends React.Component<Props, State> {
  state = {
    WSConnectionCreated: false
  }
  async componentDidMount(): Object {
    const {
      location: {
        search,
        pathname
      },
      dispatchSetUserType
    } = this.props

    localStorage.removeItem('takupro.isRefreshingToken')

    const {
      atid,
      jwtId,
      nameId,
      sessionIndex,
      userId,
      loginToken,
      route
    } = qs.parse(search)

    const userType = getUserTypeFromPathname(pathname)

    if (!userType) {
      console.error(`Could not resolve user type from pathname ${pathname}`)
      return
    }
    const privilegeFilter = getUserPrivilegeFilter(userType)
    dispatchSetUserType(userType)
    const userAPIBasePath = getUsersAPIBasePath(userType)
    const options = { basePath: userAPIBasePath }
    const authUrl = getAuthUrlByUserType(userType)
    const mandatoryQueryParamsExist = route && nameId && sessionIndex && userId && atid

    if (mandatoryQueryParamsExist) {
      this.props.saveRoute(route)
      this.props.saveNameId(nameId)
      this.props.saveSessionIndex(sessionIndex)

      const authServiceTokenData = await getAuthServiceApiToken(jwtId, authUrl)
      const authServiceApiToken = authServiceTokenData.jwt
      this.props.saveAuthServiceApiToken(authServiceApiToken)

      const accessToken = await getAccessToken(atid, authUrl)
      const authorizationData = await introspectAccessToken(accessToken, authUrl)

      this.props.saveAuthorizationData(authorizationData)

      // We need save the user ID before fetching user claims since the the request.js gets the value for X-TakuPro-User-ID header from the user store.
      this.props.saveUserClaims({ userId })

      // Creating an instance of a ws connection to be used across the application
      // Fetch user claims from Haahtela API by user ID
      getUsersSelfRequest({}, () => {
        getUsersSelfAccountsRequest({ query: { privilegeFilter, includeStatus: true } }, {}, () => {
          this.createWSConnection(authorizationData.accessToken)
          getUsersSelfLegalConsentIsUpdateRequiredRequest({}, null, null, options)
        }, null, options)
      }, null, options)
    } else if (loginToken) {
      const decodedLoginToken = await getJWTTokenDecoded({
        requestArgs: {
          token: loginToken,
          userType,
        },
        errorCb: (error: Object) => {
          const { response: { status } } = error
          window.location.assign(`/error?httpCode=${status}`)
        }
      })
      if (!decodedLoginToken) return

      const {
        authServiceApi,
        slo,
        userClaims
      } = decodedLoginToken

      if (route && slo.nameId && slo.sessionIndex && userClaims.userId && authServiceApi.atId && authServiceApi.jwtId) {
        this.props.saveRoute(route)
        this.props.saveNameId(slo.nameId)
        this.props.saveSessionIndex(slo.sessionIndex)

        const authServiceTokenData = await getAuthServiceApiToken(authServiceApi.jwtId, authUrl)

        const {
          authServiceApiToken,
          haahtelaApiUserToken,
        } = authServiceTokenData

        this.props.saveAuthServiceApiToken(authServiceApiToken)

        const accessToken = await getAccessToken(authServiceApi.atId, authUrl)

        // TODO: we should get the X-TakuPro-User-ID JWT at the same time we get the access token => we should also get a new token upon refreshing access token
        const authorizationData = await introspectAccessToken(accessToken, authUrl)
        this.props.saveAuthorizationData(authorizationData)

        // We need save the user ID before fetching user claims since the the request.js gets the value for X-TakuPro-User-ID header from the user store.
        this.props.saveUserClaims({ userId: userClaims.userId, haahtelaApiUserToken })

        // Fetch user claims from Haahtela API by user ID
        getUsersSelfRequest({}, () => {
          getUsersSelfAccountsRequest({ query: { privilegeFilter, includeStatus: true } }, {}, () => {
            this.createWSConnection(authorizationData.accessToken)
            getUsersSelfLegalConsentIsUpdateRequiredRequest({}, null, null, options)
          }, null, options)
        }, null, options)
      }
    } else {
      console.log('Processing SAML authentication callback failed! Missing some mandatory parameters.')
    }
  }

  getRedirecURLParts = (): {|
    pathname: string,
    search: string
  |} => {
    const { location } = this.props
    const searchRoute = qs.parse(location.search.split('?route=')[1])
    const { loginToken, ...rest } = searchRoute
    const [pathname, search] = qs.stringify(rest, { encode: false }).split('?')
    return {
      pathname,
      search: search ? `?${search}` : ''
    }
  }

  createWSConnection = (accessToken: string): void => {
    initWSConnection(accessToken).start().then((): void => {
      this.setState({ WSConnectionCreated: true })
    }).catch((er: Error): void => {
      // due to backend not being reliable enough, we need to let user use the application without WS connection
      this.setState({ WSConnectionCreated: true })
      console.error(er)
    })
  }

  render(): React.Node {
    const { user, legalConsentUpdateRequired } = this.props
    if (
      user.accounts.length > 0 &&
      isAuthenticatedUser(user) &&
      typeof legalConsentUpdateRequired !== 'undefined' &&
      this.state.WSConnectionCreated
    ) {
      if (legalConsentUpdateRequired === true) {
        return (
          <Redirect
            to={{
              pathname: '/terms-of-service',
              state: { from: this.getRedirecURLParts() }
            }} />
        )
      }
      console.log('User is authenticated! Access granted.')
      console.clear()
      return (
        <Redirect
          to={{
            ...this.getRedirecURLParts(),
            state: { from: this.props.location }
          }} />
      )
    }
    return (
      <Spinner />
    )
  }
}

function mapStateToProps(props: Object): Object {
  const { user, app: { selectedAccountId, legalConsentUpdateRequired } } = props
  return {
    user,
    selectedAccountId,
    legalConsentUpdateRequired
  }
}

function mapDispatchToProps(dispatch: Function): DispatchProps {
  return {
    saveAuthorizationData: (authorizationData: Object) => {
      dispatch(saveAuthorizationData(authorizationData))
    },
    saveAuthServiceApiToken: (authServiceApiToken: string) => {
      dispatch(saveAuthServiceApiToken(authServiceApiToken))
    },
    saveRoute: (route: string) => {
      dispatch(saveRoute(route))
    },
    saveNameId: (nameId: string) => {
      dispatch(saveNameId(nameId))
    },
    saveSessionIndex: (sessionIndex: string) => {
      dispatch(saveSessionIndex(sessionIndex))
    },
    saveUserClaims: (claims: Object) => {
      dispatch(saveUserClaims(claims))
    },
    dispatchSetUserType: (userType: TVDTokenUserType) => {
      dispatch(setUserType(userType))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Auth))
