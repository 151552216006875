// @flow
// Copyright © 2010–2022 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import { ELEMENTS, SPACES, WOP } from '../../constants/moduleConstants'
import { putEstimateLockWithEstimateIdRequest as refreshBuildingElementsEstimateLock } from '../../utils/generated-api-requests/buildingelements'
import { putEstimateLockWithEstimateIdRequest as refreshSpacesEstimateLock } from '../../utils/generated-api-requests/spaces'
import { putEstimateLockWithEstimateIdRequest as refreshWOPEstimateLock } from '../../utils/generated-api-requests/wop'

export const SET_CALCULATION = 'SET_CALCULATION'
export function setCalculation(calculation: string): Object {
  return {
    type: SET_CALCULATION,
    payload: { calculation }
  }
}

export const SET_APPLICATION = 'SET_APPLICATION'
export function setApplication(application: string): Object {
  return {
    type: SET_APPLICATION,
    application
  }
}

export const SET_SPACES_ESTIMATE_TYPE = 'SET_SPACES_ESTIMATE_TYPE'
export function setSpacesEstimateType(spacesEstimateType: string): Object {
  return {
    type: SET_SPACES_ESTIMATE_TYPE,
    spacesEstimateType
  }
}

export const SET_SPACES_RESULT_VIEW = 'SET_SPACES_RESULT_VIEW'
export function setSpacesResultView(spacesResultView: string): Object {
  return {
    type: SET_SPACES_RESULT_VIEW,
    spacesResultView
  }
}

export const SET_BUILDING_ID = 'SET_BUILDING_ID'
export function setBuildingId(buildingId: string): Object {
  return {
    type: SET_BUILDING_ID,
    buildingId
  }
}

export const SET_BUILDING_NAME = 'SET_BUILDING_NAME'
export function setBuildingName(buildingName: string): Object {
  return {
    type: SET_BUILDING_NAME,
    buildingName
  }
}

export const SET_ESTIMATE_NAME = 'SET_ESTIMATE_NAME'
export function setEstimateName(estimateName: string): Object {
  return {
    type: SET_ESTIMATE_NAME,
    estimateName
  }
}

export const SET_WIDGET_ACTIVE = 'SET_WIDGET_ACTIVE'
export function setWidgetActive(widgetId: string, tab?: string, widgetModifiedFalse?: boolean): Object {
  return {
    type: SET_WIDGET_ACTIVE,
    payload: { widgetId, tab, widgetModifiedFalse }
  }
}

export const SET_CALCULATION_ACTIVE = 'SET_CALCULATION_ACTIVE'
export function setCalculationActive(): Object {
  return {
    type: SET_CALCULATION_ACTIVE,
  }
}

export const SET_CALCULATION_COMPLETE = 'SET_CALCULATION_COMPLETE'
export function setCalculationComplete(): Object {
  return {
    type: SET_CALCULATION_COMPLETE,
  }
}

export const SET_ACTIVE_WIDGET_AS_TOPMOST = 'SET_ACTIVE_WIDGET_AS_TOPMOST'
export function setActiveWidgetAsTopmost(widgetId: string): Object {
  return {
    type: SET_ACTIVE_WIDGET_AS_TOPMOST,
    payload: { widgetId }
  }
}

export const SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT'
export function setSelectedAccount(selectedAccount: TVDEnum): TVDAction {
  return {
    type: SET_SELECTED_ACCOUNT,
    payload: {
      selectedAccount
    }
  }
}

export const CLEAR_SELECTED_ACCOUNT = 'CLEAR_SELECTED_ACCOUNT'
export function clearSelectedAccount(): TVDAction {
  return {
    type: CLEAR_SELECTED_ACCOUNT,
    payload: {}
  }
}

export function refreshEstimateLock(): Function {
  return (dispatch: Function, getState: Function) => {
    const { app } = getState()
    const { application, isEstimateLockedToCurrentUser } = app
    if (isEstimateLockedToCurrentUser) {
      switch (application) {
        case ELEMENTS: {
          refreshBuildingElementsEstimateLock({ body: {} }, {}, null, null, { disableSetCalculationActiveAndComplete: true })
          break
        }
        case SPACES: {
          refreshSpacesEstimateLock({ body: {} }, {}, null, null, { disableSetCalculationActiveAndComplete: true })
          break
        }
        case WOP: {
          refreshWOPEstimateLock({ body: {} }, {}, null, null, { disableSetCalculationActiveAndComplete: true })
          break
        }
        default:
          break
      }
    } else {
      console.error('Can\'t refresh lock that is not current user\'s')
    }
  }
}

export const SET_LANGUAGE_CODE = 'SET_LANGUAGE_CODE'
export function setLanguageCode(languageCode: TVDSupportedLanguageCodes): Object {
  return {
    type: SET_LANGUAGE_CODE,
    payload: {
      languageCode
    }
  }
}

export const SET_USER_TYPE = 'SET_USER_TYPE'
export function setUserType(userType: TVDTokenUserType): Object {
  return {
    type: SET_USER_TYPE,
    payload: {
      userType
    }
  }
}

export const STORE_COUNTRIES = 'STORE_COUNTRIES'
export function storeCountries(countries: Array<TVDEnum>): TVDAction {
  return {
    type: STORE_COUNTRIES,
    payload: {
      countries
    }
  }
}

export const STORE_MUNICIPALITIES = 'STORE_MUNICIPALITIES'
export function storeMunicipalities(municipalities: Array<TVDEnum>, countryId: string): TVDAction {
  return {
    type: STORE_MUNICIPALITIES,
    payload: {
      municipalities,
      countryId
    }
  }
}

export const STORE_TYPES_OF_POPULATION_CENTER = 'STORE_TYPES_OF_POPULATION_CENTER'
export function storeTypesOfPopulaitonCenter(enums: Array<TVDEnum>): TVDAction {
  return {
    type: STORE_TYPES_OF_POPULATION_CENTER,
    payload: {
      enums
    }
  }
}

export const STORE_LOCATIONS_WITHIN_POPULATION_CENTER = 'STORE_LOCATIONS_WITHIN_POPULATION_CENTER'
export function storeLocationsWithinPopulationCenter(enums: Array<TVDEnum>): TVDAction {
  return {
    type: STORE_LOCATIONS_WITHIN_POPULATION_CENTER,
    payload: {
      enums
    }
  }
}

export const SET_PRIMARY_ESTIMATE_FOR_MODULE = 'SET_PRIMARY_ESTIMATE_FOR_MODULE'
export function setPrimaryEstimateForModule(moduleName: string, estimate: string): TVDAction {
  return {
    type: SET_PRIMARY_ESTIMATE_FOR_MODULE,
    payload: {
      moduleName,
      estimate
    }
  }
}

export const SET_IS_ESTIMATE_FROZEN = 'SET_IS_ESTIMATE_FROZEN'
export function setIsEstimateFrozen(isFrozen: boolean): TVDAction {
  return {
    type: SET_IS_ESTIMATE_FROZEN,
    payload: {
      isFrozen
    }
  }
}

export const STORE_PRIMARY_ESTIMATES_FOR_MODULES = 'STORE_PRIMARY_ESTIMATES_FOR_MODULES'
export function storePrimaryEstimatesForModules(primaryEstimates: { [moduleName: string]: string }): TVDAction {
  return {
    type: STORE_PRIMARY_ESTIMATES_FOR_MODULES,
    payload: {
      primaryEstimates
    }
  }
}

export const CLEAR_PRIMARY_ESTIMATES_FOR_MODULES = 'CLEAR_PRIMARY_ESTIMATES_FOR_MODULES'
export function clearPrimaryEstimatesForModules(): TVDAction {
  return {
    type: CLEAR_PRIMARY_ESTIMATES_FOR_MODULES,
    payload: {}
  }
}

export const SET_REAL_ESTATE_ID = 'SET_REAL_ESTATE_ID'
export const setRealEstateId = (realEstateId: string): TVDAction => ({
  type: SET_REAL_ESTATE_ID,
  payload: {
    realEstateId
  }
})

export const SET_REAL_ESTATE_NAME = 'SET_REAL_ESTATE_NAME'
export const setRealEstateName = (realEstateName: string): TVDAction => ({
  type: SET_REAL_ESTATE_NAME,
  payload: {
    realEstateName
  }
})

export const SET_SITE_ID = 'SET_SITE_ID'
export const setSiteId = (siteId: string): TVDAction => ({
  type: SET_SITE_ID,
  payload: {
    siteId
  }
})

export const STORE_MFE_DETAILS = 'STORE_MFE_DETAILS'
export const storeMFEDetails = (MFEDetails: $PropertyType<TVDApplicationStore, 'MFEDetails'>): TVDAction => ({
  type: STORE_MFE_DETAILS,
  payload: {
    MFEDetails
  }
})

export const STORE_REAL_ESTATE_SIMULATION_RESULTS = 'STORE_REAL_ESTATE_SIMULATION_RESULTS'
export const storeRealEstateSimulationResults = (ids: number[]): TVDAction => ({
  type: STORE_REAL_ESTATE_SIMULATION_RESULTS,
  payload: {
    ids
  }
})

export const STORE_REAL_ESTATE_MODULE_FEATURES = 'STORE_REAL_ESTATE_MODULE_FEATURES'
export const storeRealEstateModuleFeatures = (ids: number[]): TVDAction => ({
  type: STORE_REAL_ESTATE_MODULE_FEATURES,
  payload: {
    ids
  }
})

export const SET_LICENSE_TYPE = 'SET_LICENSE_TYPE'
export const setLicenseType = (licenseType: string): TVDAction => ({
  type: SET_LICENSE_TYPE,
  payload: {
    licenseType
  }
})

export const SET_REAL_ESTATE_LAST_UPDATED_TS = 'SET_REAL_ESTATE_LAST_UPDATED_TS'
export const setRealEstateLastUpdatedTS = (realEstateLastUpdatedTS: number): TVDAction => ({
  type: SET_REAL_ESTATE_LAST_UPDATED_TS,
  payload: {
    realEstateLastUpdatedTS
  }
})

export const SET_IS_ESTIMATE_LOCKED_TO_CURRENT_USER = 'SET_IS_ESTIMATE_LOCKED_TO_CURRENT_USER'
export const setIsEstimateLockedToCurrentUser = (isEstimateLockedToCurrentUser: boolean): TVDAction => ({
  type: SET_IS_ESTIMATE_LOCKED_TO_CURRENT_USER,
  payload: {
    isEstimateLockedToCurrentUser
  }
})

export const UPDATE_POST_POLLING_TS = 'UPDATE_POST_POLLING_TS'
export const updatePostPollingTS = (): TVDAction => ({
  type: UPDATE_POST_POLLING_TS,
  payload: {
    postPollingTS: new Date().getTime()
  }
})

export const SET_INDEX_NUMBER_ON_ASSESSMENT_DATE = 'SET_INDEX_NUMBER_ON_ASSESSMENT_DATE'
export const setIndexNumberOnAssessmentDate = (indexNumberOnAssessmentDate: number): TVDAction => ({
  type: SET_INDEX_NUMBER_ON_ASSESSMENT_DATE,
  payload: {
    indexNumberOnAssessmentDate
  }
})

export const SET_IS_REFRESHING_ACCESS_TOKEN = 'SET_IS_REFRESHING_ACCESS_TOKEN'
export const setIsRefreshingAccessToken = (isRefreshingAccessToken: boolean): TVDAction => ({
  type: SET_IS_REFRESHING_ACCESS_TOKEN,
  payload: {
    isRefreshingAccessToken
  }
})

export const SET_ACTIVE_EDIT = 'SET_ACTIVE_EDIT'
export const setActiveEdit = (activeEdit: boolean): TVDAction => ({
  type: SET_ACTIVE_EDIT,
  payload: {
    activeEdit
  }
})
