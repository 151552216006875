// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { useEffect, useRef, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useStore } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { loadMFE, registeredMFEs } from '../../../utils/MFEUtils'
import { API_BASE_URL,
  ALLOW_WITH_CREDENTIALS,
  API_REALTIME_URL } from '../../../constants/envConstants'
import { SCENARIO_USE, scenarioNewConstructionUrl, summaryUrl, scenarioRepairConstructionUrl } from '../../../constants/moduleConstants'
import { openGenericErrorModal } from '../../../utils/modalUtils'
import { useAppInitialization } from '../../../ProjectManagement/common/hooks/useAppInitialization'
import { headerHeight } from '../../../components/common/Header/Header'
import { getWSConnection } from '../../../utils/websocketUtils'
import { createQueryParamsString } from '../../../utils/urlUtil'
import { setMainViewActiveIndex } from '../../../actions/view/actions'
import { refreshUserAccessToken } from '../../../actions/user'
import { setActiveRealEstate } from '../../../actions/activeRealEstate'
import { getIsFeatureEnabledInSet } from '../../../utils/features'
import { FEATURE_SCENARIO_RENOVATION_USE, FEATURE_SCENARIO_LCA } from '../../../constants/features'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: headerHeight
  }
}))

const ScenarioUseMFEContainer = (): React$Element<'div'> => {
  const classes = useStyles()
  const history = useHistory()
  const { dispatch } = useStore()
  const [isInitialized] = useAppInitialization({ module: SCENARIO_USE })
  const moduleOnUnmountRef = useRef<$PropertyType<TVDMFEFunctionality, 'onUnmount'>>()
  const {
    realEstateId,
    accessToken,
    haahtelaApiUserToken,
    userId,
    languageCode,
    selectedAccountId,
    userPrivileges,
    primaryEstimates,
    buildingId,
    activeRealEstate,
    realEstateModuleFeatures
  } = useSelector(({
    app,
    user,
    activeRealEstate: activeRealEstateStore
  }: TVDReduxStore): {|
    realEstateModuleFeatures: $PropertyType<TVDApplicationStore, 'realEstateModuleFeatures'>,
    languageCode: $PropertyType<TVDApplicationStore, 'languageCode'>,
    selectedAccountId: $PropertyType<TVDApplicationStore, 'selectedAccountId'>,
    accessToken: $PropertyType<TVDUserAuthorization, 'accessToken'>,
    haahtelaApiUserToken: $PropertyType<TVDUserClaims, 'haahtelaApiUserToken'>,
    userId: $PropertyType<TVDUserClaims, 'userId'>,
    userPrivileges: $PropertyType<TVDUserStore, 'userPrivileges'>,
    realEstateId: $PropertyType<TVDApplicationStore, 'realEstateId'>,
    primaryEstimates: $PropertyType<TVDApplicationStore, 'primaryEstimates'>,
    buildingId: $PropertyType<TVDApplicationStore, 'buildingId'>,
    activeRealEstate: TVDActiveRealEstateStore,
  |} => ({
    languageCode: app.languageCode,
    selectedAccountId: app.selectedAccountId,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    userPrivileges: user.userPrivileges,
    realEstateId: app.realEstateId,
    primaryEstimates: app.primaryEstimates,
    buildingId: app.buildingId,
    activeRealEstate: activeRealEstateStore,
    realEstateModuleFeatures: app.realEstateModuleFeatures
  }))

  useEffect(() => () => {
    if (moduleOnUnmountRef.current) {
      moduleOnUnmountRef.current()
    }
  }, [])

  const handleNavigateToPageNotFound = useCallback((): void => {
    history.push({
      pathname: '/page-not-found'
    })
  }, [
    history
  ])

  const getScenarioTypeURL = (scenarioType: string): string => {
    switch (scenarioType) {
      case 'newConstruction': {
        return scenarioNewConstructionUrl
      }
      case 'repairConstruction': {
        return scenarioRepairConstructionUrl
      }
      default: {
        console.error(`Error when resolving scenario type URL: scenario type ${scenarioType} is not among the supported types`)
        return ''
      }
    }
  }

  const handleRealEstateSelection = useCallback((newRealEstateId: string, realEstateName: string, scenarioType: string): void => {
    history.push({
      pathname: `${getScenarioTypeURL(scenarioType)}/${realEstateName}`,
      search: createQueryParamsString({ realEstateId: newRealEstateId })
    })
  }, [
    history
  ])


  const navigateToProject = useCallback((realestateId: string): void => {
    history.push({
      pathname: summaryUrl,
      search: createQueryParamsString({ realEstateId: realestateId })
    })
  }, [
    history
  ])

  const WSConnection = getWSConnection()

  const registeredScenarioUseModule = registeredMFEs['scenario-use-module']
  useEffect(() => {
    if (
      languageCode &&
      accessToken &&
      haahtelaApiUserToken &&
      userId &&
      selectedAccountId &&
      userPrivileges &&
      isInitialized &&
      primaryEstimates &&
      buildingId &&
      activeRealEstate &&
      realEstateModuleFeatures
    ) {
      const render = (module: TVDMFEFunctionality): void => {
        moduleOnUnmountRef.current = module.onUnmount
        const moduleRender = module.render || null
        const navigateToRealEstateListing = () => {
          history.push({
            pathname: '/',
            search: createQueryParamsString({ accountId: selectedAccountId })
          })
          dispatch(setMainViewActiveIndex(0))
        }
        const setActiveRealEstateToStore = (realEstate: TVDRealEstate): void => {
          dispatch(setActiveRealEstate(realEstate))
        }
        if (moduleRender) {
          moduleRender(
            'new-construction-scenario-module',
            {
              APIRealtimeURL: API_REALTIME_URL,
              APIBaseURL: API_BASE_URL,
              languageCode,
              accessToken,
              haahtelaApiUserToken,
              userId,
              selectedAccountId,
              openGenericErrorModal,
              userPrivileges,
              ALLOW_WITH_CREDENTIALS,
              realEstateId,
              primaryEstimates,
              WSConnection,
              buildingId,
              navigateToRealEstateListing,
              setActiveRealEstateToStore,
              activeRealEstate,
              handleRealEstateSelection,
              navigateToProject,
              realEstateModuleFeatures,
              handleNavigateToPageNotFound,
              refreshUserAccessToken,
              allowRenovationScenarioCreation: getIsFeatureEnabledInSet(FEATURE_SCENARIO_RENOVATION_USE),
              isLCAShown: getIsFeatureEnabledInSet(FEATURE_SCENARIO_LCA)
            }
          )
        }
      }
      if (registeredScenarioUseModule) {
        render(registeredScenarioUseModule)
      } else {
        loadMFE('scenario_use', 'new-construction-scenario-module', (module: TVDMFEFunctionality): void => {
          render(module)
        })
      }
    }
  }, [
    dispatch,
    history,
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    selectedAccountId,
    userPrivileges,
    registeredScenarioUseModule,
    realEstateId,
    isInitialized,
    primaryEstimates,
    WSConnection,
    buildingId,
    activeRealEstate,
    handleRealEstateSelection,
    navigateToProject,
    realEstateModuleFeatures,
    handleNavigateToPageNotFound
  ])
  return (
    <div className={classes.root} id='new-construction-scenario-module' />
  )
}

export default ScenarioUseMFEContainer
