// @flow
// Copyright © 2010–2023 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '../AppBar/AppBar'
import SummaryView from '../SummaryView/SummaryView'
import MainViewSwitch from '../../MainViewSwitch'
import Header from '../../../components/common/Header/Header'
import DrawerRight from '../../../components/common/DrawerRight/DrawerRight'
import { useAccountSelection } from '../../common/hooks/accountSelection'
import Spaces from '../../../components/scenes/spaces/Spaces'
import ModalsContainer from '../../../components/containers/ModalsContainer/ModalsContainer'
import BuildingElements from '../../../components/scenes/buildingElements/buildingElements'
// import LocationAnalysis from '../../../LocationAnalysis/components/scenes/LocationAnalysis'
import LocationAnalysisMFE from '../../../components/scenes/LocationAnalysis/LocationAnalysisMFE'
import Wop from '../../../components/scenes/Wop/Wop'
import { useAppInitialization } from '../../common/hooks/useAppInitialization'
import {
  REAL_ESTATES,
  SPACES,
  ELEMENTS,
  WOP,
  LOCAN,
  PROFITABILITY,
  summaryUrl,
  PROCUREMENT_ELEMENTS,
  RENOVATION_PROGRAM,
  usageAndMaintenanceUrl,
  USAGE_AND_MAINTENANCE,
  scenarioNewConstructionUrl,
  scenarioRepairConstructionUrl,
  procurementElementsNewUrl
} from '../../../constants/moduleConstants'
import { getIsFeatureEnabledInSet } from '../../../utils/features'
import { FEATURE_LOCATION_ANALYSIS_MFE } from '../../../constants/features'

import EstimateLockManager from '../../../components/common/EstimateLockManager/EstimateLockManager'
import Widget from '../../../components/containers/widgets/Widget'
import ProfitabilityView from '../SummaryView/components/ProfitabilityView/ProfitabilityView'
import RenovationProgram from '../../../components/scenes/RenovationProgram/RenovationProgram'
import ProcurementElements from '../../../components/scenes/ProcurementElements/ProcurementElements'
import UsageAndMaintenance from '../../../components/scenes/UsageAndMaintenance/UsageAndMaintenance'
import SplashScreen from '../SplashScreen/SplashScreen'
import AccessTokenTimer from '../../../components/common/AccessTokenTimer/AccessTokenTimer'
import { FORBIDDEN_STATUS } from '../../../utils/requests/request'
import useCheckMaintenanceBreaks from '../../../ProjectManagement/common/hooks/checkMaintenanceBreaks'
import ScenarioUseMFEContainer from '../../../components/containers/ScenarioUseMFEContainer/ScenarioUseMFEContainer'
import ProcurementElementsNewMFEContainer from '../../../components/containers/ProcurementElementsNewMFEContainer/ProcurementElementsNewMFEContainer'
import ScenarioUseRepairConstructionMFEContainer
  from '../../../components/containers/ScenarioUseRepairConstructionMFEContainer/ScenarioUseRepairConstructionMFEContainer'

const useStyles = makeStyles(({ palette }: TVDTheme) => ({
  appViewRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    background: palette.white,
  },
  appContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
  },
  appContentWrapper: {
    display: 'flex',
    paddingLeft: 90,
    height: '100%',
    width: '100%',
    background: palette.white
  }
}))

const useWidgets = () => {
  const widgets = useSelector((store: TVDReduxStore): { [widgetId: string]: Object } => store.widgets)
  return Object.keys(widgets).map((widgetId: string): React$Element<Widget> =>
    <Widget key={widgetId} {...widgets[widgetId]} />)
}

export const AppContainer = ({ module, children }: Object) => {
  const [isInitialized, lockPUTFailResponse] = useAppInitialization({ module })
  const classes = useStyles()

  return (
    <div className={classes.appContainer}>
      <AppBar />
      <div className={classes.appContentWrapper}>
        {isInitialized && children}
        {module !== REAL_ESTATES && isInitialized && lockPUTFailResponse?.status !== FORBIDDEN_STATUS && <EstimateLockManager />}
      </div>
    </div>
  )
}

const AppView = (): React$Element<'div'> => {
  const classes = useStyles()
  const widgets = useWidgets()
  const { isUserPrivilegesReady } = useAccountSelection()
  const selectedAccountIdFromStore = useSelector(({
    app: {
      selectedAccountId
    }
  }: TVDReduxStore): $PropertyType<TVDApplicationStore, 'selectedAccountId'> => selectedAccountId)
  const [checkMaintenanceBreaks] = useCheckMaintenanceBreaks()

  useEffect(() => {
    if (selectedAccountIdFromStore) {
      checkMaintenanceBreaks()
    }
  }, [selectedAccountIdFromStore, checkMaintenanceBreaks])

  return (
    <div className={classes.appViewRoot}>
      <Header />
      {isUserPrivilegesReady && <DrawerRight />}
      <Switch>
        <Route exact path='/'>
          {
            isUserPrivilegesReady && (
            <>
              <SplashScreen showTabs />
              <MainViewSwitch />
            </>
          )
          }
        </Route>
        <Route exact path={summaryUrl}>
          {isUserPrivilegesReady && <AppContainer module={REAL_ESTATES}><SummaryView /></AppContainer>}
        </Route>
        <Route exact path='/tilat/:realEstateName'>
          {isUserPrivilegesReady && <AppContainer module={SPACES}><Spaces /></AppContainer>}
        </Route>
        <Route exact path='/rakennusosat/:realEstateName'>
          {isUserPrivilegesReady && <AppContainer module={ELEMENTS}><BuildingElements /></AppContainer>}
        </Route>
        {
          // <Route exact path='/location-analysis/:realEstateName'>
          //   {isUserPrivilegesReady && <AppContainer module={LOCAN}><LocationAnalysis /></AppContainer>}
          // </Route>
        }
        {
          getIsFeatureEnabledInSet(FEATURE_LOCATION_ANALYSIS_MFE) &&
            (
              <Route exact path='/location-analysis/:realEstateName'>
                {isUserPrivilegesReady && (
                <AppContainer
                  module={LOCAN}>
                  <LocationAnalysisMFE />
                </AppContainer>
                )}
              </Route>

            )
        }
        <Route exact path='/wop/:realEstateName'>
          {isUserPrivilegesReady && <AppContainer module={WOP}><Wop /></AppContainer>}
        </Route>
        <Route exact path='/profitability/:realEstateName'>
          {isUserPrivilegesReady && <AppContainer module={PROFITABILITY}><ProfitabilityView /></AppContainer>}
        </Route>
        <Route exact path='/renovation-program/:realEstateName'>
          {isUserPrivilegesReady && <AppContainer module={RENOVATION_PROGRAM}><RenovationProgram /></AppContainer>}
        </Route>
        <Route exact path='/procurement-elements/:realEstateName'>
          {isUserPrivilegesReady && <AppContainer module={PROCUREMENT_ELEMENTS}><ProcurementElements /></AppContainer>}
        </Route>
        <Route exact path={`${usageAndMaintenanceUrl}/:realEstateName`}>
          {isUserPrivilegesReady && <AppContainer module={USAGE_AND_MAINTENANCE}><UsageAndMaintenance /></AppContainer>}
        </Route>
        <Route exact path={`${scenarioNewConstructionUrl}/:realEstateName`}>
          {isUserPrivilegesReady && <ScenarioUseMFEContainer />}
        </Route>
        <Route exact path={`${scenarioRepairConstructionUrl}/:realEstateName`}>
          {isUserPrivilegesReady && <ScenarioUseRepairConstructionMFEContainer />}
        </Route>
        <Route exact path={`${procurementElementsNewUrl}/:realEstateName`}>
          {isUserPrivilegesReady && <AppContainer module={PROCUREMENT_ELEMENTS}><ProcurementElementsNewMFEContainer /></AppContainer>}
        </Route>

        <Route path='*' />
      </Switch>
      {widgets}
      <ModalsContainer />
      <AccessTokenTimer />
    </div>
  )
}

export default AppView
