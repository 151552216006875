// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'

import ControlBar from '../../../common/ControlBar/ControlBar'
import TabsPlain from '../../../common/TabsPlain/TabsPlain'
import AreaEquipments from '../../../common/Tabs/AreaEquipments/AreaEquipments'
import PropertyContainer from '../../../common/Tabs/Properties/PropertyContainer/PropertyContainer'
import FeaturesHOC from '../../../hocs/FeaturesHOC/FeaturesHOC'
import Feature from '../../Feature/Feature'

import { CALCULATION_PROPERTIES } from '../../../../constants/contentTypes'
import { PROPERTIES_TAB, EQUIPMENTS_TAB } from '../../../../constants/moduleConstants'
import { TECHNICAL_BUILDING_SERVICES } from '../../../../constants/registryConstants'
import { UPDATE_TECHNICAL_BUILDING_SERVICES, TECHNICAL_BUILDING_SERVICES_TOGGLE } from '../../../../constants/widgetKeys'
import { setWidgetContentProps } from '../../../../actions/widgets'
import { getIsFeatureEnabledInSet } from '../../../../utils/features'
import { FEATURE_SITE_EQUIPMENT_TAB } from '../../../../constants/features'
import SpaceSiteEquipmentsTabMFEContainer from '../../../containers/SpacesSiteEquipmentsTabMFEContainer/SpacesSiteEquipmentsTabMFEContainer'

const styles = ({ palette, typography }: TVDTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    minHeight: 0, // fixes vertical scrolling when using firefox https://stackoverflow.com/a/28639686
  },
  contentWrapper: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0%'
  },
  resultBarWrapper: {
    flexBasis: '23px'
  },
  labelBold: {
    fontWeight: 600,
    font: `14px ${typography.fontFamilyBase}`,
    color: palette.nevada,
    paddingLeft: '12px'
  },
  labelNormal: {
    fontWeight: 400,
    font: `14px ${typography.fontFamilyBase}`,
    color: palette.nevada
  }
})

type DispatchProps = {|
  dispatchToggleTechnicalBuildingServices: Function, // function to toggle technicalBuildingServices checkbox
  openSiteEquipmentProductAssemblyWidget: (id: string) => void, // function to open SiteEquipmentProductAssemblyWidget from SpacesSiteEquipmentsTab
  handleCloseProductAssemblyWidget: () => void, // function to handle closing SiteEquipmentProductAssemblyWidget
|}

type MappedProps = {|
  updateTechnicalBuildingServices: boolean, // state of updateTechnicalBuildingServices checkbox - is sent as a query parameter in patch request
  isEstimateFrozen: $PropertyType<TVDApplicationStore, 'isEstimateFrozen'>, // if estimate is frozen
  isEstimateLockedToCurrentUser: $PropertyType<TVDApplicationStore, 'isEstimateLockedToCurrentUser'>, // if estimate is locked to current user
  widgetType: string, // type set in the widget store
  disabled: boolean, // disabled status from widget store
  activeTab: string, // name of currently edited tab
|}

type Props = {|
  ...DispatchProps,
  ...MappedProps,
  classes: Object, // withStyles classes object
  t: Function, // translate function
  widgetId: string, // id set in the widget store
  features: TVDFeatureHOCProps, // features from Store and helper functions from the HOC
  propertiesStoreId: string, // key for properties Store
  listStoreId: string, // key for list Store
|}


export class CalculationProperties extends Component<Props> {
  tabs(): Array<Object> {
    const {
      t,
      activeTab,
      widgetType,
      widgetId,
      disabled,
      isEstimateLockedToCurrentUser,
      propertiesStoreId,
      listStoreId,
      isEstimateFrozen,
      openSiteEquipmentProductAssemblyWidget,
      handleCloseProductAssemblyWidget
    } = this.props
    const tabs = [
      {
        testId: 'properties-tab',
        props: {
          label: t('tabs._PROPERTIES_'),
          disabled: activeTab !== '' && activeTab !== PROPERTIES_TAB
        },
        content: <PropertyContainer
          widgetId={widgetId}
          disabled={disabled || !isEstimateLockedToCurrentUser || isEstimateFrozen}
          actionText={t('userModifiedInfo._SPACES_RESET_DEFAULT_VALUE_')}
          tab={PROPERTIES_TAB}
          type={widgetType}
          resourceId={CALCULATION_PROPERTIES}
          propertiesStoreId={propertiesStoreId} />
      },
      {
        testId: 'equipment-tab',
        props: {
          disabled: activeTab !== '' && activeTab !== EQUIPMENTS_TAB,
          label: t('tabs._AREA_EQUIPMENT_')
        },
        content: <AreaEquipments
          widgetId={widgetId}
          disabled={disabled}
          resourceId={CALCULATION_PROPERTIES}
          listStoreId={listStoreId} />
      }
    ]
    const siteEquipment = {
      testId: 'site-equipment-tab',
      props: {
        disabled: activeTab !== '' &&
          (activeTab === EQUIPMENTS_TAB || activeTab === PROPERTIES_TAB),
        label: t('tabs._AREA_EQUIPMENT_')
      },
      content:
  <SpaceSiteEquipmentsTabMFEContainer
    setSelectedSiteEquipmentAssemblyId={openSiteEquipmentProductAssemblyWidget}
    handleCloseProductAssemblyWidget={handleCloseProductAssemblyWidget} />
    }
    if (getIsFeatureEnabledInSet(FEATURE_SITE_EQUIPMENT_TAB)) tabs.push(siteEquipment)
    return tabs
  }

  checkboxes(): Array<TVDCheckBoxProps> {
    const {
      t,
      disabled,
      classes,
      features,
      isEstimateLockedToCurrentUser,
      dispatchToggleTechnicalBuildingServices,
      updateTechnicalBuildingServices,
      isEstimateFrozen
    } = this.props
    return features.getEnabledFeatures([
      {
        id: 'technicalBuildingServices',
        disabled: disabled || isEstimateFrozen || !isEstimateLockedToCurrentUser,
        checked: updateTechnicalBuildingServices,
        name: TECHNICAL_BUILDING_SERVICES,
        label: () => (
          <Typography className={classes.labelBold}>
            {t(`widgets._${UPDATE_TECHNICAL_BUILDING_SERVICES}_`)}
            <span className={classes.labelNormal}>
              {t(`widgets._${TECHNICAL_BUILDING_SERVICES_TOGGLE}_`)}
            </span>
          </Typography>
        ),
        onChange: () => dispatchToggleTechnicalBuildingServices(updateTechnicalBuildingServices)
      }
    ])
  }

  render(): React$Element<any> {
    const {
      classes,
    } = this.props
    return (
      <div className={classes.root}>
        <Feature name={UPDATE_TECHNICAL_BUILDING_SERVICES}>
          <ControlBar checkboxes={this.checkboxes()} />
        </Feature>
        <div className={classes.contentWrapper}>
          <TabsPlain tabs={this.tabs()} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ app, widgets }: TVDReduxStore, { widgetId, type }: Object): MappedProps => {
  const {
    activeTab,
    isEstimateLockedToCurrentUser,
    isEstimateFrozen
  } = app
  const {
    [widgetId]: {
      disabled,
      contentProps: {
        updateTechnicalBuildingServices
      } = {}
    } = {}
  } = widgets
  return {
    activeTab,
    widgetType: type,
    disabled,
    isEstimateLockedToCurrentUser,
    updateTechnicalBuildingServices,
    isEstimateFrozen
  }
}

const mapDispatchToProps = (dispatch: Function) => ({
  dispatchToggleTechnicalBuildingServices: (current: boolean) => {
    dispatch(setWidgetContentProps(CALCULATION_PROPERTIES, { updateTechnicalBuildingServices: !current }))
  }
})

export default compose(
  withStyles(styles),
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps),
  FeaturesHOC
)(CalculationProperties)
