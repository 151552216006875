// @flow
// Copyright © 2010–2024 Haahtela-kehitys Oy. All rights reserved. Unauthorized use, disclosure, reproduction or modification of this source code file (or any part thereof) is strictly prohibited.
import React, { useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { loadMFE } from '../../../utils/MFEUtils'
import { API_BASE_URL, ALLOW_WITH_CREDENTIALS } from '../../../constants/envConstants'
import { PROCUREMENT_ELEMENTS } from '../../../constants/moduleConstants'
import { getEstimateHistoryObj } from '../../../utils/urlUtil'
import { headerHeight } from '../../../components/common/Header/Header'
import WebWorker from '../../../utils/WebWorker'
import { getIsFeatureEnabledInSet } from '../../../utils/features'
import { FEATURE_LICENCE_TYPE_CHIP } from '../../../constants/features'
import { openGenericErrorModal } from '../../../utils/modalUtils'

const useStyles = makeStyles(({ palette }: TVDTheme) => ({
  root: {
    height: '100%',
    width: '100%',
    paddingTop: headerHeight,
    background: palette.gray20,
    paddingRight: '8px'
  }
}))

const ProcurementElements = (): React$Element<'div'> => {
  const classes = useStyles()
  const history = useHistory()
  const {
    calculation,
    buildingId,
    realEstateId,
    accessToken,
    haahtelaApiUserToken,
    userId,
    languageCode,
    selectedAccountId,
    siteId,
    realEstateName,
    isEstimateLockedToCurrentUser,
    isEstimateFrozen,
    licenseType
  } = useSelector(({
    app,
    user,
  }: TVDReduxStore): Object => ({
    languageCode: app.languageCode,
    buildingId: app.buildingId,
    selectedAccountId: app.selectedAccountId,
    calculation: app.calculation,
    realEstateId: app.realEstateId,
    realEstateName: app.realEstateName,
    accessToken: user.authorization.accessToken,
    haahtelaApiUserToken: user.claims.haahtelaApiUserToken,
    userId: user.claims.userId,
    siteId: app.siteId,
    isEstimateLockedToCurrentUser: app.isEstimateLockedToCurrentUser,
    isEstimateFrozen: app.isEstimateFrozen,
    licenseType: app.licenseType
  }))

  const navigateToProcurementEstimate = useCallback((estimateId: string, beforeNavigation?: () => void): void => {
    if (beforeNavigation) {
      beforeNavigation()
    }
    history.push(getEstimateHistoryObj({
      app: PROCUREMENT_ELEMENTS,
      realEstateName,
      accountId: selectedAccountId,
      estimateId,
      realEstateId
    }))
  }, [
    history,
    realEstateName,
    selectedAccountId,
    realEstateId
  ])


  useEffect(() => {
    loadMFE('procurement_elements', 'procurement-elements-module-new', (module: TVDMFEFunctionality) => {
      if (
        calculation &&
        languageCode &&
        accessToken &&
        haahtelaApiUserToken &&
        userId &&
        selectedAccountId &&
        realEstateId &&
        buildingId &&
        siteId
      ) {
        const render = module.render || null
        if (render) {
          render(
            'procurement-elements-new',
            {
              APIBaseURL: API_BASE_URL,
              languageCode,
              accessToken,
              haahtelaApiUserToken,
              userId,
              selectedAccountId,
              calculation,
              realEstateId,
              buildingId,
              siteId,
              navigateToProcurementEstimate,
              isEstimateLockedToCurrentUser,
              WebWorker,
              isEstimateFrozen,
              licenseType: getIsFeatureEnabledInSet(FEATURE_LICENCE_TYPE_CHIP) ? licenseType : null,
              ALLOW_WITH_CREDENTIALS,
              openGenericErrorModal
            }
          )
        }
      }
    })
  }, [
    languageCode,
    accessToken,
    haahtelaApiUserToken,
    userId,
    selectedAccountId,
    calculation,
    realEstateId,
    buildingId,
    siteId,
    navigateToProcurementEstimate,
    isEstimateLockedToCurrentUser,
    isEstimateFrozen,
    licenseType
  ])
  return (
    <div className={classes.root} id='procurement-elements-new' />
  )
}

export default ProcurementElements

